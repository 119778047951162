.PageHeader{
  .wrapper{
    padding-top: 35vh;
    padding-bottom: 70px;

    @include responsive($tabletSmall){
      padding-bottom: get-vw(70);
    }
  }

  .container{
    @include flex();

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
    
    h1{
      @include responsive($tabletSmall){
        max-width: space(30);
      }
    }

    .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 40px;
        align-self: start;
      }

      @include responsive($tabletSmall){
        align-self: end;
        margin-left: auto;
      }
    }
  }

  .visual__container{
    @include imgRatio(1440, 530, var(--grey-l));
    // margin-top: 70px;

    // @include responsive($tabletSmall){
    //   margin-top: get-vw(70);
    // }
  }
}