.Intro {
  @include cover(fixed);
  z-index: 2000;
  transform: translateY(-100%);
  background: $base;
  visibility: hidden;

  html.is--dark & {
    background: darken($base, 5%);
  }

  body.--animating & {
    visibility: visible;
  }

  .inner {
    @include cover();
    @include flex(center, center);
  }

  .Brand {
    overflow:hidden;

    svg > * {
      fill:var(--white);
    }
  }
}
