.Formulas{
  .swiper{
    margin: 0 0.625em;

    &[data-slides="1"],
    &[data-slides="2"],
    &[data-slides="3"]{
      .swiper-navigation{
        @include responsive($tabletSmall){
          display: none;
        }
      }
    }

    &-slide{
      @include responsive($tabletSmall, max){
        max-width: 90%;
      }

      @include responsive($tabletSmall){
        max-width: get-vw(463);
      }
      
      & + .swiper-slide{
        margin-left: 0.625em;
      }
    }

    &-navigation{
      order: 3;

      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(50);
      }
    }
  }
}