.Block__steps {

    .step { 
        margin-bottom: 32px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(32);
            @include flex(initial,space-between);
            gap: space(2);
        }

        .number {
            font-size: 90px;
            color: $green-l1;
            line-height: 0.8;
            @include flex(center,initial);
            gap: 30px;
            width: 90%;
            
            @include responsive($tabletSmall) {
                @include font-vw(90);
                flex-direction: column;
                gap: get-vw(30);
                width: auto;
                min-width: get-vw(110);
            }

            &:after {
                content: '';
                background: $green-l1;
                width: 100%;
                height: 2px;
                display: block;

                @include responsive($tabletSmall) {
                    height: get-vw(120);
                    width: get-vw(2);
                }
            }
        }
        
        &:last-child {
                @include responsive($tabletSmall) {
                .number::after {
                    display: none;
                }
            }
        }

        .text {
            flex-grow: 2;
            width: 100%;

            @include responsive($tabletSmall) {
                margin-bottom: get-vw(40);
            }

            h3 {
                margin-top: 0;

                @include responsive($tabletSmall, max) {
                    margin-top: 32px;
                }
            }
    
            p {
                font-size: 17px;
                line-height: 1.7;
                color: $text-green;
                max-width: 85%;
    
                @include responsive($tabletSmall) {
                    @include font-vw(17);
                }
            }
        }
    }

    &.--linear {
        @include flex(initial,space-between);
        flex-wrap: wrap;
        gap: space(1.5);

        .step {
            flex-direction: column;
            gap: 16px;
            flex: 1;
            min-width: 240px;

            @include responsive($tablet) {
                min-width: 0;
            }

            .number {
                @include flex(center,space-between);
                flex-direction: row;
                gap: 30px;

                &:after {
                    content: '';
                    background: $green-l1;
                    height: 2px;
                    width: 100%;
                    display: block;
                    
                    @include responsive($tabletSmall) {
                        width: get-vw(60);
                    }
                }
            }

            h3 {
                font-size: 21px;
                margin-top: 32px;

                @include responsive($tabletSmall) {
                    @include font-vw(21);
                }
            }

            &:last-child {
                @include responsive($tabletSmall) {
                    .number::after {
                        display: none;
                    }
                }
            }
        }
    }
}