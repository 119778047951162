.Accordion{
  position: relative;
  margin: 0 space(1) 40px;
  padding: 0 0 40px;

  @include noTouchHover() {
    &:after{
      transform: scaleX(1);
    }
  }

  &:before,
  &:after{
    @include content();
    position: absolute;
    bottom: 0; left: 0;
    height: 1px;
    width: 100%;
    transform-origin: 0% 0%;
    transition: transform 400ms ease;
  }
  
  &:before{
    background: var(--accordion-border);
  }
  
  &:after{
    background: var(--accordion-active-border);
    transform: scaleX(0);
    transition: transform 1300ms var(--ease-in-out-quart);
  }

  &.--open{
    &:after{
      transform: scaleX(1);
    }

    .Accordion__head{
      .Icon[data-name="chevron"]{
        transform: scaleY(-1);
      }
    }

    .Accordion__body{
      .wswyg--content{
        transform: translate(0%, 0%);
      }
    }
  }

  &__head{
    @include flex();
    font-size: 28px;
    color: var(--black);
    width: 100%;

    @include responsive($tabletSmall){
      @include font-vw(28);
    }

    @include responsive($deskXXL){
      @include font-vw(20);
    }

    span{
      flex: 1;
      padding-right: 5%;
    }

    .Icon[data-name="chevron"]{
      margin-left: auto;
      transition: transform 400ms var(--ease-in-out-quart);
    }
  }

  &__body{
    max-height: 0;
    transition: max-height 750ms var(--ease-in-out-quart);

    .wswyg--content{
      margin-top: 34px;
      color: var(--text-green);
      transform: translate(0%, 20%);
      transition: transform 750ms var(--ease-in-out-quart);
    }
  }
}