.PageHeaderContact{
  padding-top: 20vh;

  // #Close{
  //   @include flex(center, center);
  //   height: get-vw(50);
  //   width: get-vw(50);
  //   min-height: 30px;
  //   min-width: 30px;
  //   background: #013434;
  //   border-radius: 10px;
  //   position: absolute;

  //   @include responsive($tabletSmall, max){
  //     top: 20px; right: 20px;
  //   }
    
  //   @include responsive($tabletSmall){
  //     top: get-vw(20); right: get-vw(20);
  //   }

  //   body:not(.--show-contact) &{
  //     display: none;
  //   }

  //   &:before,
  //   &:after{
  //     @include content();
  //     position: absolute;
  //     height: 1px;
  //     width: get-vw(15);
  //     min-width: 10px;
  //     background: var(--main-green);
  //   }

  //   &:before{
  //     transform: rotate(-45deg);
  //   }
    
  //   &:after{
  //     transform: rotate(45deg);
  //   }
  // }

  .inner{
    @include responsive($tabletSmall){
      max-width: space(35);
      margin: 0 auto;
    }
  }

  h1{
    font: 400 get-vw(90) $nm;
    color: var(--white);

    @include responsive($tabletSmall) {
      @include font-vw(60);
    }

    @include responsive($deskXXL){
      @include font-vw(40);
    }

    strong{
      color: var(--main-green);
    }
  }

  .infos{
    @include flex();
    flex-wrap: wrap;
    gap: 2em;
    margin-top: 2em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(40);
    }

    .info{
      @include flex();

      > .Link{
        margin-left: 0.5em;
        color: var(--white);

        &:before{
          background: var(--white);
        }
      }
    }
  }
}