.OfferCard{
  @include flex(flex-end);
  border-radius: var(--radius);
  
  @include responsive($tablet){
    height: get-vw(650);
    min-height: 650px;
  }

  &[data-type="club"]{
    background: var(--main-green);

    .head{
      span{ color: var(--green-d8) }
      h2{ color: var(--green-l7) }
    }

    .up{
      @include responsive($tablet){
        background: var(--main-green);
      }

      &:after{
        @include responsive($tablet){
          background: var(--main-green);
          background: linear-gradient(0deg, rgb(0, 170, 141) 0%, rgba(0,170,141,0.5) 100%);
        }
      }
    }
  }

  &[data-type="cart"]{
    background: #d0f5ef;
    
    .head{
      span{ color: var(--green-l1) }
      h2{ color: var(--green-d2) }
    }
    
    .up{
      @include responsive($tablet){
        background: #d0f5ef;
      }

      &:after{
        @include responsive($tablet){
          background: #d0f5ef;
          background: linear-gradient(0deg, rgb(208, 245, 239) 0%, rgba(208, 245, 239,0.5) 100%);
        }
      }

      p{ color: #6EA59C }
      
      .details{
        > span{ color: var(--main-green) }
        > .Icon[data-name="arrow"] svg > *{ stroke: var(--main-green) }
      }
    }
  }

  &[data-type="liberal"]{
    background: #0d3534;
    
    .head{
      span{ color: var(--green-l7) }
      h2{ color: var(--main-green) }
    }
    
    .up{
      @include responsive($tablet){
        background: #0d3534;
      }

      &:after{
        @include responsive($tablet){
          background: rgb(13,53,52);
          background: linear-gradient(0deg, #0d3534 0%, rgba(13,53,52,0.5) 100%);
        }
      }
    }

    .visual__container{
      mix-blend-mode: lighten;
    }
  }  

  @include noTouchHover() {
    .head{
      @include responsive($tablet){
        transform: translate(0, -30%);
      }
    }

    .up{
      @include responsive($tablet){
        opacity: 1;
        pointer-events: all;
        transform: translate(0, 0);
        transition-delay: 50ms;
      }
    }

    .visual__container{
      @include responsive($tablet){
        transform: translate(0, -10%) scale(0.8);
      }
    }
  }

  .inner{
    @include flex(flex-start);
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 17em 2em 2em;
    position: relative;
  }

  .head{
    @include flex();
    flex-direction: column;

    @include responsive($tablet, max){
      margin-bottom: 1.25em;
    }

    @include responsive($tablet){
      margin-top: auto;
      transition: transform 550ms var(--ease-in-out-quart) 50ms;
    }

    span{
      font: 700 11px $nm;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $green-l1;
      margin-bottom: 1em;
    }

    h2{
      color: var(--green-d2);
      font: 400 40px $nm;

      @include responsive($tabletSmall){
        @include font-vw(30);
      }

      @include responsive($desk) {
        @include font-vw(40);
      }

      @include responsive($deskXXL){
        @include font-vw(30);
      }
    }
  }

  .up{
    @include responsive($tablet){
      position: absolute;
      bottom: 0; left: 0;
      width: 100%;
      padding: 0 2em 2em;
      transform: translate(0, 100%);
      opacity: 0;
      pointer-events: none;
      transition: transform 550ms var(--ease-in-out-quart), 
                  opacity 550ms var(--ease-in-out-quart);
    }

    &:after{ 
      @include responsive($tablet){
        @include content();
        position: absolute;
        height: 50px;
        width: 100%;
        left: 0; bottom: 100%;
      }
    }

    p{
      font-size: 24px;
      line-height: 1.25;
      color: var(--green-l7);
      margin-bottom: 1.25em;
      
      @include responsive($tabletSmall){
        @include font-vw(24);
        margin-bottom: get-vw(20);
      }

      @include responsive($tablet){
        min-height: 4em;
      }

      @include responsive($deskXXL){
        @include font-vw(20);
      }
    }

    .details{
      @include flex();

      > span{
        color: var(--white);
      }

      > .Icon[data-name="arrow"]{
        margin-left: 0.5em;
        transform: scaleX(-1);

        svg > * {
          stroke: var(--white);
        }
      }
    }
  }  

  .visual__container{
    @include imgRatio(150, 150);
    position: absolute;
    top: 5%; left: 0;
    transform-origin: 0% 0%;
    transition: transform 550ms var(--ease-in-out-quart);
    min-width: 100px;

    @include responsive($tablet, max){
      max-width: get-vw(200);
    }

    @include responsive($tablet){
      max-width: get-vw(300);
    }
  }
}
