/* -----
Misc
----- */

.is--hidden {
	display: none !important;
}

.is--centered {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.is--left {
	margin-right: auto;
	text-align: left;
}

.is--right{
	margin-left: auto;
	text-align: right;
}

.vh {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    left: -9999px;
    top: -9999px;
}

.no--br br {
  content: '';
  width: 9px;
  height: 18px;
  &:before {
    content: ' '
  }
}

.--oh{
  overflow: hidden;
}

.--pr{
  position: relative;
}

/* -----
Lazyload
----- */

html:not(.no-js) .lazyload,
html:not(.no-js) .lazyloading {
  opacity: 0;
}

html.no-js img.sr-only {
  display:block;
  top:0; left:0;
  width:100%;
  height:100%;
  clip:inherit;
  object-fit:cover;
}

.lazyloaded {
  position: relative;
  opacity: 1;
  transition: opacity 400ms linear 400ms;
}

/* -----
Prllx
----- */

*[data-prllxfrom] {
  will-change:transform;

	&[data-mobile-fix] {
		@include responsive($tabletSmall, max) {
			transform: none!important;
		}
	}
}

/* -----
Layout
----- */
.--clipping{
  @include responsive($tabletSmall, max){
    clip-path: inset(0.3125em 0.25em 0.3125em round 0.625em 0.625em 0.625em);
  }

  @include responsive($tabletSmall){
    // clip-path: inset(0.3125em 0.625em 0.3125em round 0.625em 0.625em 0.625em);
    clip-path: inset(0.625em 0.625em 0.3125em round 0.625em 0.625em 0.625em);
  }
}

/* -----
Colors
----- */
.--c-green{
  color: var(--main-green);
}

.--c-green-l7{
  color: var(--green-l7);
}

.--c-white{
  color: var(--white);
}

.--c-black{
  color: var(--black);
}

.--c-green-d{
  color: var(--green-d8);
}

/* -----
Backgrounds
----- */
.--bg-grey{
  background: var(--grey-l8);
}

.--bg-grey-l7{
  background: var(--grey-l7);
}

.--bg-white{
  background: var(--white);
}

.--bg-green{
  background: var(--main-green);
}

.--bg-green-d{
  background: var(--green-d8);
}

.--bg-green-d1{
  background: var(--green-d1);
}


.--mobile-swiper{
  @include responsive($tabletSmall){
    .swiper-wrapper{
      transform: none !important;
      cursor: initial !important;
    }

    .swiper-slide{
      margin: 0 !important;
    }
  }
}

.--no-sb{
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
}