.Btn {
  display: inline-flex;
  text-align: center;
  outline: 0;
  border: 0;
  cursor: pointer;
  padding: 1.063em 1.25em;
  border-radius: var(--radius);
  font: 500 16px $nm;

  @include responsive($tabletSmall){
    @include font-vw(16);
  }

  @include noTouchHover() {
    .Icon[data-name="arrow"]{
      transform: translate(30%, 0);
    }
  }
  
  &:not(.--secondary):not(.--third):not(.--fourth):not(.--white):not(.--space){
    background: $main-green;
    color: var(--white);
  }

  &.--secondary{
    background: $grey-l7;
    color: var(--green-d2);
  }

  &.--third{
    background: var(--green-d8);
    color: var(--white);
  }

  &.--fourth{
    background: var(--black);
    color: var(--white);
  }

  &.--white{
    background: var(--white);
    color: $main-green;

    .Icon svg > * { stroke: $main-green!important; }
  }

  .Icon[data-name="arrow"]{
    transition: transform 500ms var(--ease-in-out-quart);
    margin-left: 0.3em;

    .Header &{
      display: none;
    }

    svg {
      transform: rotate(-180deg);

      > *{ stroke: var(--white) }
    }
  }

  .Icon[data-name="download"]{
    transition: transform 500ms var(--ease-in-out-quart);
    margin-left: 0.3em;

    svg {
      > *{ stroke: var(--white) }
    }
  }

  // Header buttons
  .Header .Nav__buttons &{
    padding: 0.95em;
  }

  &.--space{
    background: var(--black);
    color: var(--white);
  }

  &.--contact{}

  &__txt {
    color: inherit;
    font-weight: 500;
  }
}