.PoppConcept{
  .container > .head{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      margin-bottom: 3em;
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      margin-bottom: get-vw(70);
    }

    > *{
      width: 100%;
    }

    h2{
      @include responsive($tabletSmall){
        max-width: space(17);
      }
    }

    .wswyg--content{
      @include responsive($tabletSmall){
        max-width: space(16);
        margin-left: auto;
        margin-right: space(3);
      }
    }
  }

  .comparison {
    > .head{
      @include responsive($tabletSmall, max){
        display: none;
      }
      
      @include responsive($tabletSmall){
        @include flex(flex-start);
        margin-bottom: get-vw(24);
      }

      .ComparisonTitle{
        &:nth-child(2){
          @include responsive($tabletSmall){
            padding-left: 45%;
          }
        }
      }
    }

    > .list{
      @include responsive($tabletSmall){
        margin: 0 space(-1);
      }
    }
  }
}