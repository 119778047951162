.TestimonialsCard{
  background: var(--white);
  border-radius: var(--radius);
  padding: 3em 3em 3.5em;
  
  &, figure{
    height: 100%;
  }

  figure{
    @include flex();
    flex-direction: column;
    margin: 0;
  }

  blockquote.wswyg--content{
    padding-bottom: 2em;

    p{
      font-size: 16px;

      @include responsive($tabletSmall){
        @include font-vw(16);
      }
    }
  }

  figcaption{
    @include flex();
    margin-top: auto;

    .visual__container{
      @include imgRatio(50, 50);
      min-width: 38px;
      max-width: get-vw(38);
      clip-path: circle(40%);
      margin-right: 26px;
    }

    .Icon{
      margin-right: 30px;
    }

    .author{
      > span{
        font: 400 14px $nm;
        display: block;

        @include responsive($tabletSmall){
          @include font-vw(18);
        }

        &:first-child{
          color: var(--black);
          font-weight: 500;
        }

        &:nth-child(2){
          color: $green-d1;
        }
      }
    }
  }
}