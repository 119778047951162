.BackLink{
  display: inline-flex;
  align-items: center;

  @include noTouchHover() {
    > .Icon[data-name="arrow"]{
      transform: translate(-15%, 0);
    }

    > span{
      transform: translate(-5%, 0);
      background-size: 100% 1px;
      background-position: 0% 100%;
    }
  }

  > .Icon[data-name="arrow"]{
    transition: transform 450ms var(--ease-in-out-circ);

    svg > *{
      stroke: var(--main-green);
    }
  }

  > span{
    font: 500 16px $nm;
    margin-left: 0.5em;
    display: inline;
    background-image: linear-gradient(var(--main-green), var(--main-green));
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    transition: transform 450ms var(--ease-in-out-circ) 50ms,
                background-size 450ms var(--ease-in-out-circ) 50ms;

    @include responsive($tabletSmall){
      @include font-vw(16);
    }
  }
}