.PoppIntroduction{
  background: var(--white);

  h2{
    text-align: center;
    margin-bottom: 0.75em;
  }

  .wswyg--content{
    @include responsive($tabletSmall){
      max-width: space(27);
      margin: 0 auto;
    }

    * {
      text-align: center;
    }
  }
}