.HomeOffers{
  > .container{
    @include flex(flex-start);
    flex-direction: column;

    @include responsive($tabletSmall){
      padding: 0 calc(100vw/24);
    }
  }

  .container > .head{
    @include flex(flex-start);
    width: 100%;

    @include responsive($tabletSmall, max){
      flex-direction: column;
      margin-bottom: 3em;
    }
    
    @include responsive($tabletSmall){
      margin-bottom: get-vw(50);
    }

    h2, 
    p{
      @include responsive($tabletSmall){
        max-width: space(14);
      }
    }

    p{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        padding-top: 1em;
        margin-left: auto;
      }
    }
  }

  .container > .grid{
    display: grid;
    width: 100%;
    
    @include responsive($tabletSmall, max){
      grid-gap: 1em;
    }
    
    @include responsive($tabletSmall){
      grid-template-columns: repeat(4, 1fr);
      grid-gap: get-vw(30);
    }

    @include responsive($tablet){
      grid-template-columns: repeat(3, 1fr);
    }

    > *{
      &:not(:last-child){
        @include responsive($tabletSmall){
          grid-column: span 2;
        }

        @include responsive($tablet){
          grid-column: span 1;
        }
      }

      &:last-child{
        @include responsive($tabletSmall){
          grid-column: span 4;
        }

        @include responsive($tablet){
          grid-column: span 1;
        }
      }
    }
  }

  .container > .Btn{
    align-self: center;

    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(40);
    }
  }
}
