.ArticlePreview{
  border-radius: var(--radius);
  display: grid;
  align-items: center;
  grid-gap: 1.5em 3em;
  padding: 1.5em;
  background: var(--grey-l8);

  & + .ArticlePreview{
    @include responsive($tabletSmall, max){
      margin-top: 1em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(10);
    }
  }

  @include responsive($tabletSmall){
    grid-template-columns: 0.8fr 1fr;
  }

  > .visual__container{
    @include imgRatio(450, 260);
    clip-path: inset(0 0 0 round 0.625em 0.625em 0.625em);
  }

  > .inner{
    @include flex(flex-start);
    flex-direction: column;

    > span{
      font: 400 30px $nm;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include responsive($tabletSmall){
        @include font-vw(30);
      }

      @include responsive($deskXXL){
        @include font-vw(20);
      }
    }

    > p{
      margin-top: 0.8em;
      
      @include responsive($tabletSmall){
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    .Btn{
      margin-top: 2em;
    }
  }
}