.PoppContentBlock{
  @include flex(flex-start);

  @include responsive($tabletSmall, max){
    flex-direction: column;
  }
  
  @include responsive($tabletSmall){
    padding: 0 space(1);
    align-items: center;
  }

  &:nth-child(odd){
    > .wrapper{
      @include responsive($tabletSmall){
        margin-left: space(4);
      }
    }
  }
  
  &:nth-child(even){
    @include responsive($tabletSmall){
      flex-direction: row-reverse;
    }

    > .wrapper{
      @include responsive($tabletSmall){
        margin-right: space(4);
      }
    }
  }

  & + .PoppContentBlock{
    @include responsive($tabletSmall, max){
      margin-top: 2.5em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(120);
    }
  }

  > .visual__container,
  > .wrapper{
    width: 100%;

    @include responsive($tabletSmall){
      max-width: space(18);
    }
  }

  > .wrapper{
    .wswyg--content{
      margin-top: 1.5em;
    }

    .Btn{
      margin-top: 3em;
    }
  }

  > .visual__container{
    @include imgRatio(540, 650);
    clip-path: inset(0% 0% 0% round 1.25em 1.25em 1.25em);

    @include responsive($tabletSmall, max){
      margin-bottom: 2em;
      max-width: 400px;
    }
  }
  
}