.ScrollTitle{
  height: 200vh;
  min-height: 1000px;
  position: relative;
  padding-top: 30vh;
  margin: 0 space(1);

  h2{
    text-align: center;
    margin: 0 auto;
    position: sticky;
    top: 50%;
    transform: translate(0, -50%);

    @include responsive($tabletSmall, max){
      font-size: 6vw;
    }

    br{
      @include responsive($tabletSmall, max){
        display: none;
      }
    }
  }
}