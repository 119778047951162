.SolutionMenu{
  @include flex(center);
  padding: 10px;
  border-radius: var(--radius);
  transition: background 500ms var(--ease-out-quart);

  @include noTouchHover() {
    background: #114a4a;
  }

  @include responsive($tablet, max){
    font-size: 16px;
  }

  .visual__container{
    @include imgRatio(50, 50);
    width: get-vw(50);
    margin-right: 0.5em;

    @include responsive($tablet, max){
      min-width: 25px;
    }
  }

  span{
    color: var(--white);
  }
}