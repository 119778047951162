.Block__intro {
    .visual__container {
        position: relative;
        border-radius: 10px;
        @include imgRatio(1020, 460, $base);
        margin-bottom: 40px;

        @include responsive($tabletSmall) {
            border-radius: get-vw(10);
            margin-bottom: get-vw(50);
        }

        &:after {
            display: none;
        }
    }

    p {
        font-size: 20px;
        line-height: 1.7;
        color: $text-green;

        @include responsive($tabletSmall) {
            max-width: 80%;
            @include font-vw(20);
        }
    }
}