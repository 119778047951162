.OfferSlide{
  border: 1px solid var(--grey-l9);
  height: 100%;
  border-radius: var(--radius);

  @include responsive($tabletSmall, max){
    padding: 2em 1.5em 1em;
  }

  @include responsive($tabletSmall){
    padding: get-vw(60) get-vw(60) get-vw(30);
  }

  .tag{
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;

    .visual__container{
      @include imgRatio(20, 20);
      margin-right: 0.5em;
      width: get-vw(20);

      @include responsive($tabletSmall, max){
        min-width: 15px;
      }
    }
  }

  > span{
    font: 400 24px $nm;
    color: var(--black);
    margin-top: 1em;
    margin-bottom: 0.8em;

    @include responsive($tabletSmall){
      @include font-vw(24);
    }

    @include responsive($deskXXL){
      @include font-vw(20);
    }
  }  

  > p{
    color: var(--text-green);
  }

  .availability{
    @include flex(center);
    flex-wrap: nowrap;

    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(20);
    }

    
    > span{
      font: 500 14px $nm;
      margin-left: 1em;
      color: var(--main-green);
      
      @include responsive($tabletSmall){
        @include font-vw(14);
      }
    }
  }
}