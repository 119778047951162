.Article{
  padding-top: 0;
  
  .visual__container{
    &:first-child {
      @include imgRatio(1440, 420, $base);
    }

    &:after{
      @include content();
      @include cover();
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    }

    body.--show-article .wswyg--content div:not(.Block__intro)  &{
      @include imgRatio(1440, 600);
    }
    
    body:not(.--show-article) .wswyg--content div:not(.Block__intro) &{
      @include imgRatio(1440, 420);

      @include responsive($tabletSmall, max){
        clip-path: inset(0.3125em 0.25em 0.3125em round 0.625em 0.625em 0.625em);
      }

      @include responsive($tabletSmall){
        clip-path: inset(0.625em 0.625em 0.3125em round 0.625em 0.625em 0.625em);
      }
    }
  }

  .wrapper{
    @include responsive($tabletSmall, max){
      margin-top: 3em;
    }

    @include responsive($tabletSmall){
      max-width: space(30);
      margin: get-vw(100) auto 0;
    }

    .head{
      @include flex(flex-start);
      margin-bottom: 2em;

      @include responsive($tabletSmall, max){
        flex-direction: column;
        gap: 2em;
      }

      @include responsive($tabletSmall){
        align-items: center;
        justify-content: space-between;
      }
    }

    h1{
      @include responsive($tabletSmall, max){
        margin-bottom: 1em;
      }

      @include responsive($tabletSmall){
        margin-bottom: get-vw(40);
      }
    }

    h2{
      // font: 500 30px $nm;
      color: var(--green-d2);

      // @include responsive($tabletSmall){
      //   @include font-vw(30);
      // }
    }

    .wswyg--content{
      @include responsive($tabletSmall, max){
        margin-top: 3em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(50);
      }

      h2, h3, h4, h5, h6{
        @include responsive($tabletSmall, max){
          margin-bottom: 1.5em;
        }

        @include responsive($tabletSmall){
          margin-bottom: get-vw(20);
        }
      }

      ul, ol, p, li{
        color: var(--text-green);
      }

      .plyr__container{
        position: relative;
        min-height: 400px;
        border-radius: 10px;

        @include responsive($tabletSmall, max){
          margin: 2em 0;
        }
    
        @include responsive($tabletSmall){
          height: get-vw(500);
          margin: get-vw(50) 0;
        }
      }

      .plyr__control.plyr__control--overlaid{
        @include flex(center, center);
        background: var(--main-green);
        opacity: 1;
        height: 80px;
        width: 80px;

        svg{
          fill: var(--white);
        }
      }
    }
  }
}