.Icon {
  position: relative;
  @include imgRatio(1, 1);
  // min-width: 24px;

  svg {
    @include cover();
    fill: $base;
  }

  &[data-name="clipboard"]{
    @include imgRatio(16, 16);
    width: get-vw(16);

    @include responsive($tabletSmall, max){
      min-width: 16px;
    }

    svg > *{
      fill: none;
    }
  }

  &[data-name="url"]{
    @include imgRatio(20, 20);
    width: get-vw(20);

    @include responsive($tabletSmall, max){
      min-width: 20px;
    }

    svg > *{
      fill: none;
      stroke: var(--main-green);
    }
  }

  &[data-name="whatsapp"]{
    @include imgRatio(20, 20);
    width: get-vw(20);

    @include responsive($tabletSmall, max){
      min-width: 20px;
    }
  }

  &[data-name="whatsapp-alt"]{
    @include imgRatio(30, 30);
    width: get-vw(30);
    transform: translate(0, -0.2em);

    @include responsive($tabletSmall, max){
      min-width: 30px;
    }
  }

  &[data-name="email"]{
    @include imgRatio(17, 14);
    width: get-vw(17);

    @include responsive($tabletSmall, max){
      min-width: 17px;
    }
  }

  &[data-name="linkedin"]{
    @include imgRatio(15, 14);
    width: get-vw(15);

    @include responsive($tabletSmall, max){
      min-width: 15px;
    }
  }

  &[data-name="facebook"]{
    @include imgRatio(9, 16);
    width: get-vw(9);

    @include responsive($tabletSmall, max){
      min-width: 9px;
    }
  }

  &[data-name="twitter"]{
    @include imgRatio(18, 14);
    width: get-vw(18);

    @include responsive($tabletSmall, max){
      min-width: 18px;
    }
  }

  &[data-name="play"] {
    @include imgRatio(12, 15);
    min-width: 12px;
  }

  &[data-name="user"] {
    @include imgRatio(34, 37);
    min-width: 34px;
  }

  &[data-name="chevron"]{
    @include imgRatio(24, 24);
    min-width: 24px;

    svg > *{
      fill: none;
      stroke: var(--main-green);
    }
  }

  &[data-name="tick"]{
    @include imgRatio(17, 12);
    min-width: 17px;

    svg > *{
      fill: none;
      stroke: var(--main-green);
    }

    .ComparisonTitle &{
      @include responsive($tabletSmall, max){
        min-width: 17px;
      }

      @include responsive($tabletSmall){
        width: get-vw(17);
        min-width: 10px;
      }
    }
  }

  &[data-name="tel"],
  &[data-name="@"]{
    @include imgRatio(30, 30);
    width: get-vw(30);
    min-width: 15px;

    @include responsive($tabletSmall, max){
      min-width: 25px;
    }

    svg{
      fill: none;
    }
  }

  &[data-name="chevron"]{
    width: get-vw(20);
    min-width: 10px;

    @include responsive($tabletSmall, max){
      min-width: 20px;
    }

    svg{
      fill: none;
    }
  }

  &[data-name="arrow"]{
    width: get-vw(20);
    min-width: 10px;

    @include responsive($tabletSmall, max){
      min-width: 20px;
    }

    svg{
      fill: none;
    }
  }

  &[data-name="triangle"]{
    @include imgRatio(23, 12);

    @include responsive($tabletSmall){
      min-width: 23px;
      width: get-vw(23);  
    }
  }

  &[data-name="substract"]{
    @include imgRatio(9, 11);

    @include responsive($tabletSmall, max){
      display: none;
    }

    @include responsive($tabletSmall){
      min-width: 20px;
      width: get-vw(20);
    }
  }

  &[data-name="lock"] {
    @include imgRatio(16, 16);
    width: get-vw(16);

    @include responsive($tabletSmall, max){
      min-width: 16px;
    }

    svg > *{
      fill: none;
    }
  }

  &[data-name="arrow-top"]{
    @include imgRatio(24, 24);
    width: get-vw(24);

    svg{
      fill: none;
    }
  }

  &[data-name="cross"]{
    @include imgRatio(13, 12);
    width: get-vw(13);
    
    @include responsive($tabletSmall, max){
      min-width: 13px;
    }

    @include responsive($tabletSmall){
      min-width: 6px;
    }

    svg{
      fill: none;

      > *{
        stroke: var(--main-green);
      }
    }
  }

  &[data-name="download"]{
    @include imgRatio(1, 1);
    min-width: 20px;

    @include responsive($tabletSmall){
      min-width: get-vw(20);
    }

    svg{
      fill: none;

      > *{
        stroke: $white;
      }
    }
  }

  &[data-name="triangle-small"]{
    @include imgRatio(11, 6);

    @include responsive($tabletSmall, max){
      min-width: 11px;
    }
  }

  &[data-name="search"]{
    @include imgRatio(20, 20);
    width: get-vw(20);

    @include responsive($tabletSmall, max){
      min-width: 20px;
    }

    svg{
      fill: none;
    }

    .Form__actions &{
      svg > *{ stroke: #7b7b7b; }
    }
  }
}
