// Neue Montreal 
@font-face {
  font-family: NeueMontreal;
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url(../fonts/NeueMontreal-Regular.woff2) format("woff2"), url(../fonts/NeueMontreal-Regular.woff) format("woff");
}
@font-face {
  font-family: NeueMontreal;
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url(../fonts/NeueMontreal-Medium.woff2) format("woff2"), url(../fonts/NeueMontreal-Medium.woff) format("woff");
}
@font-face {
  font-family: NeueMontreal;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url(../fonts/NeueMontreal-Bold.woff2) format("woff2"), url(../fonts/NeueMontreal-Bold.woff) format("woff");
}

// Garaje
@font-face {
  font-family: Garaje;
  font-weight: 1000;
  font-style: normal;
  font-display: block;
  src: url(../fonts/Garaje-Black.woff2) format("woff2"), url(../fonts/Garaje-Black.woff) format("woff");
}