.Partners{
  h2, 
  .grid{
    @include responsive($tabletSmall){
      margin: 0 space(1);
    }
  }

  h2{
    @include responsive($tabletSmall, max){
      margin-bottom: 1em;
    }

    @include responsive($tabletSmall){
      max-width: space(15);
      margin-bottom: get-vw(50);
    }
  }

  .grid{
    display: grid;
    grid-gap: space(1);
    grid-template-columns: repeat(3, 1fr);

    @include responsive($mobile){
      grid-template-columns: repeat(6, 1fr);
    }

    @include responsive($tabletSmall){
      grid-template-columns: repeat(8, 1fr);
    }

    > a,
    > div{
      @include flex(center, center);
      border: 1px solid var(--grey-l9);
      border-radius: 0.3125em;
      aspect-ratio: 1;

      img{
        height: auto;
        width: 100%;
        max-width: 80%;
      }
    }
  }
}