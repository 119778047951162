.OfferMenu{
  @include flex(center, center);
  color: var(--white);
  border: 1px solid rgba(255, 255, 255, 0.085);
  border-radius: var(--radius);
  padding: 0.7em;
  
  @include responsive($tabletSmall){
    margin-top: auto;
  }

  &.--liberal{
    .visual__container{
      background: #002525;
      clip-path: inset(0px 0px 0px round 7px 7px 7px);
    }
  }

  .visual__container{
    @include imgRatio(100, 100);
    width: get-vw(100);

    @include responsive($tablet, max){
      min-width: 50px;
    }
  }

  .wrapper{
    margin-left: 25px;

    .subtitle{
      color: var(--text-green);
    }

    .ttl{
      margin-top: 0.1em;

      @include responsive($tablet, max){
        font-size: 22px;
      }
    }
  }  
}