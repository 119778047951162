.Keyword{
  @include flex(center, center);
  background: var(--black);
  border-radius: 0.325em;
  padding: 1em;
  transition: background 400ms var(--ease-in-out-cubic);
  font: 400 2.8vw $nm;

  @include responsive($tabletSmall){
    @include font-vw(20);
  }

  @include responsive($deskXXL){
    @include font-vw(18);
  }

  &.--active{
    background: var(--green-l8);

    > .content {
      color: var(--text-green);
      
      span{
        background-size: 100% 1px;
        transition-delay: 250ms;
      }
    }

    .check{
      border-color: var(--green-l7);
      background: var(--green-l7);
      transition: background 400ms var(--ease-in-out-cubic) 250ms, 
                  border-color 400ms var(--ease-in-out-cubic) 0ms;

      .Icon[data-name="tick"]{
        transform: scale(1);
        transition-delay: 300ms;
      }
    }
  }

  > .content{
    color: var(--white);
    transition: color 400ms var(--ease-in-out-cubic);
    margin-right: 1em;

    span{
      display: inline;
      background-image: linear-gradient(var(--text-green), var(--text-green));
      background-position: 0% 60%;
      background-repeat: no-repeat;
      background-size: 0% 1px;
      transition: background-size 400ms var(--ease-in-out-cubic) 0ms;

      // &:after{
      //   @include content();
      //   position: absolute;
      //   top: 50%; left: 0;
      //   height: 1px; width: 100%;
      //   background: var(--text-green);
      //   transform: translate(0%, 100%) scaleX(0);
      //   transform-origin: 0% 0%;
      //   transition: transform 400ms var(--ease-in-out-cubic) 0ms;
      // }
    }
  }
  
  .check{
    min-width: 1.5em;
    min-height: 1.5em;
    border: 2px solid #333333;
    transition: background 400ms var(--ease-in-out-cubic) 0ms, 
                border-color 400ms var(--ease-in-out-cubic) 0ms;
    

    .Icon[data-name="tick"]{
      transform: scale(0);
      transition: transform 400ms var(--ease-in-out-expo) 0ms;
      min-width: 0.7em;
    }
  }
}