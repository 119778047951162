.PlansCard{
  @include flex();
  flex-direction: column;
  border-radius: var(--radius);
  background: var(--green-d8);
  padding: 2em 2.5em;
  height: 100%;

  .wswyg--content{
    padding-bottom: 7em;

    *{
      color: #839A96;

      strong{
        color: var(--white);
      }
    }
  }

  > span{
    margin-top: auto;
    font: 400 80px $nm;

    @include responsive($tabletSmall){
      @include font-vw(80);
    }


    @include responsive($deskXXL){
      @include font-vw(40);
    }
  }
}