.Block__testimony {

    @include responsive($tabletSmall) {
        @include flex(stretch, center);
        gap: space(3);
    }

    .visual__container, .plyr__container {
        position: relative;
        width: 100%;
        min-height: 400px;
        border-radius: 10px;
    
        @include responsive($tabletSmall, max) {
            border-radius: get-vw(10);
        }

        @include responsive($tabletSmall) {
            max-width: get-vw(420);
            min-height: get-vw(400);
        }
    
        @include responsive($tabletSmall, max) {
            margin-bottom: 35px;
        }

        &::after {
            display: none!important;
        }

        img, video {
            @include cover();
            object-fit: cover;
        }
    }

    .plyr__container {
        margin: 0!important;
        height: auto!important;

        .plyr__play {
            transform: translate(-50%, -50%);
            min-width: 25%;
            width: 25%;
            &:before { background: $white; }
            .Icon {
                min-width: 15%;
                width: 15%;
                svg > * { fill: $green-l1; }
            }
        }
    }

    blockquote {
        width: 100%;
        border: 0;
        font-style: normal;
        padding: 0 20px;

        @include responsive($tabletSmall) {
            padding: get-vw(36) 0;
            flex-basis: 45%;
        }

        .Icon {
            margin-bottom: 20px;
            display: block;
            width: 42px;
            @include imgRatio(42, 31);

            @include responsive($tabletSmall) {
                width: get-vw(42);
                height: get-vw(30);
                margin-bottom: get-vw(40);
            }

            svg {
                @include cover();
            }
        }

        p {
            font-size: 16px;
            line-height: 1.7;
            color: $text;
            margin-bottom: 24px;

            @include responsive($tabletSmall) {
                @include font-vw(16);
                margin-bottom: get-vw(24);
            }
        }
        
        span.name {
            font-weight: 500;
            font-size: 18px;
            line-height: 1.3;
            color: $base;
            display: block;

            @include responsive($tabletSmall) {
                @include font-vw(18);
            }
        }

        span.position {
            font-size: 16px;
            line-height: 19px;
            color: $green-d1;
            display: block;

            @include responsive($tabletSmall) {
                @include font-vw(16);
            }
        }
    }
}