.AboutValues{
  padding-top: 0;

  .Banner{
    margin-bottom: 2.5em;

    @include responsive($tabletSmall){
      margin-bottom: get-vw(150);
    }
  }

  &.--content-layout.--alt{
    .headline{
      @include responsive($tabletSmall){
        margin-left: 0;
        max-width: space(26);
      }
    }

    .content{
      @include responsive($tabletSmall){
        max-width: space(19);
        margin-right: space(2);
        margin-top: get-vw(80);
      }

      p + h3{
        @include responsive($tabletSmall, max){
          margin-top: 2em;
        }

        @include responsive($tabletSmall){
          margin-top: get-vw(50);
        }
      }

      h3{
        font: 400 28px $nm;
        color: var(--main-green);

        @include responsive($tabletSmall){
          @include font-vw(38);
        }

        @include responsive($deskXXL){
          @include font-vw(28);
        }
      }
    }
  }
}