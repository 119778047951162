.Footer {
	padding: 40px 0 0;
	background: var(--white);
	bottom: 0; left: 0;
	z-index: 1;
	width: 100%;

	body.--show-contact &{
		display: none;
	}

	.container {
		@include flex($justify: space-between);
		margin-bottom: get-vw(150);

		@include responsive($tabletSmall, max){
			flex-direction: column;
		}

		nav {
			width: 100%;
			display: grid;
			grid-gap: 40px;

			@include responsive($tabletSmall, max){
				margin-bottom: 2em;
			}

			@include responsive($mobile){
				grid-template-columns: repeat(2, auto);
				grid-gap: space(2);
			}
			
			@include responsive($tabletSmall){
				grid-template-columns: repeat(4, auto);
				grid-gap: get-vw(45);
				max-width: get-vw(1100);
			}
		}

	}

	&__nav{
		&-block{
			// Small title of the block (uppercase)
			> span{
				text-transform: uppercase;
				font: 700 12px $nm;
				letter-spacing: 0.05em;
				margin-bottom: 24px;
				color: var(--green-d8);
				
				@include responsive($tabletSmall){
					@include font-vw(12);
					margin-bottom: get-vw(24);
				}
			}

			.Btn{
				.Icon[data-name="arrow"]{
					display: none;
				}
			}

			// Link container
			> ul{
				display: grid;
				grid-gap: 10px;

				@include responsive($tabletSmall){
					grid-gap: get-vw(10);
				}

				li{
					font: 400 16px $nm;

					@include responsive($tabletSmall){
						@include font-vw(16);
					}
				}
			}

			// With the buttons
			&.--externals{
				@include responsive($tabletSmall, max){
					margin-top: 50px;
				}

				> ul{
					@include responsive($mobileSmall){
						grid-template-columns: repeat(2, auto);
						justify-content: start;
					}
					
					@include responsive($tabletSmall){
						grid-template-columns: repeat(1, auto);
					}

					li{
						@include responsive($tabletSmall){
							justify-self: end;
						}
					}
				}
			}
		}
	}

	&__logo{
		@include imgRatio(1420, 158);
		position: relative;
		margin: 0 get-vw(10);

		button{
			@include cover();
			margin-top: 0.75%;

			@include noTouchHover() {
				svg{
					transform: scale(1.01);
				}
			}
		}

		svg{
			transition: transform 270ms $SineEaseInOut;

			> *{
				fill: $main-green;
			}
		}
	}
}
