.Skills{
  > .container{
    @include flex(flex-start, flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
  }

  h2{
    @include responsive($tabletSmall, max){
      margin-bottom: 1.2em;
    }

    @include responsive($tabletSmall){
      max-width: space(16);
      margin-left: space(1);
      position: sticky;
      top: 2em;
      padding-bottom: 2em;
    }
  }

  .skills-list{
    display: grid;
    grid-gap: 1em;

    @include responsive($tabletSmall){
      max-width: space(24);
      margin-left: auto;
    }
  }

  .Btn{
    justify-self: start;
  }
}