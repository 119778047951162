$menuBreakpoint: $tablet;

.Header {
  top: 0; left: 0;
  width: 100%;
  z-index: 120;
  pointer-events: none; 
  transition: transform 500ms var(--ease-in-out-quart);

  @include responsive($menuBreakpoint, max){
    position: absolute;
    top: 1em;
  }

  &:not(.--dark){
    .Nav__link.Link{
      > span{    
        body:not(.--scrolled) &{
          @include responsive($menuBreakpoint){
            background-image: linear-gradient(var(--white), var(--white));
          }
        }
      }
    }
  }

  @include responsive($menuBreakpoint){
    position: fixed;
  }

  body.--show-article &,
  body.--show-contact &{
    transform: translate(0, -100%);
  }

  body.--scrolled &{
    &:not(.--visible){
      @include responsive($menuBreakpoint){
        transform: translate(0, -100%);
      }
    }

    &.--visible{
      > .container {
        > .wrapper{
          @include responsive($menuBreakpoint){
            background: var(--white);
          }

          .substract{
            @include responsive($menuBreakpoint){
              opacity: 1;
            }
          }
        }
      }
    }
  }

  > .container {
    @include responsive($tabletSmall) {
      padding: 0 get-vw(12);
    }

    @include responsive($menuBreakpoint){
      top: 0;
    }

    > .wrapper{
      @include flex(center);
      position: relative;
      transition: background 500ms var(--ease-in-out-quart);

      @include responsive($menuBreakpoint, max){
        justify-content: space-between;
        top: 0;
      }

      @include responsive($tabletSmall){
        padding: 2em 1em;
      }
    }

    .substract{
      @include flex(flex-start, space-between);
      pointer-events: none;
      opacity: 0;
      position: absolute;
      top: 99%; left: 0;
      width: 100%;
      transition: opacity 500ms var(--ease-in-out-quart);

      @include responsive($tabletSmall, max){
        display: none;
      }

      .Icon[data-name="substract"]{
        &:first-child{
          transform: scaleX(-1);
        }
      }
    }
  }
}


.substract{
  body.--scrolled.--show-article .Header.--visible &{
    opacity: 0 !important;
  }
}