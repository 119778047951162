.Block__cta {
    background: $green-l7;
    text-align: center;
    border-radius: 10px;
    color: $green-d1;
    padding: 64px 20px;

    @include responsive($tabletSmall) {
        padding: get-vw(64) space(1);
    }

    &.--simple {
        background: $white;

        .Btn {
            background: $white!important;
            color: var(--main-green)!important;

            .Icon svg > * { stroke: var(--main-green)!important;}
        }
    }

    .inner {
        margin: auto;
        padding: 0 30px;

        @include responsive($tabletSmall) {
            max-width: 85%;
        }

        h3 {
            color: var(--green-d2)!important;
        }

        > *+* {
            margin-top: 30px;

            @include responsive($tabletSmall) {
                margin-top: get-vw(30);
            }
        }
    }
}