.Block__navigation {
    ul {
        list-style-type: none;
        margin: 0;
        @include flex(center, initial);
        background-color: $grey-l8;
        max-width: max-content;
        border-radius: 10px;
        color: $text;
        font-weight: 500;
        padding: 5px;
        gap: 5px;
        overflow-x: scroll;
    
        @include responsive($tabletSmall) {
        flex-wrap: wrap;
        overflow-x: initial;
        }
    
        @include responsive($deskXXL) {
            padding: get-vw(5);
            gap: get-vw(5);
            border-radius: get-vw(10);
        }
        
        &::-webkit-scrollbar{
            display: none;
        }
        
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        li {
            margin: 0!important;
        }

        a, button {
            @include flex(center, flex-start);
            gap: 6px;
            padding: 12px 14px;
            line-height: 1.2;
            border-radius: 5px;
            color: $text;
            font-weight: 500;
            font-size: 16px;
            text-decoration: none!important;

            @include responsive($tabletSmall) {
                padding: get-vw(12) get-vw(14);
                @include font-vw(16);
                gap: get-vw(6);
            }

            @include noTouchHover() {
                background: $white;
                color: $base;
            }
            
            &[target="_blank"] {
                &::after {
                    content: '';
                    display: block;
                    background: url('../imgs/SVG/icon-external.svg');
                    background-size: contain;
                    width: 16px;
                    height: 16px;
    
                    @include responsive($tabletSmall) {
                      width: get-vw(16);
                      height: get-vw(16);
                    }
                }
            }
        }
    }
}