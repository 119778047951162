.IconsMarquee{
  display: flex;

  .marquee{
    @include flex();
    animation: marquee 10s infinite linear; 
  }

  .marquee-box{
    height: get-vw(150);
    width: get-vw(150);
    min-height: 75px;
    min-width: 75px;
    margin-right: get-vw(90);
  
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

@keyframes marquee{
  0%{
    transform: translateX(0%)
  }

  100%{
    transform: translateX(-100%)
  }
}