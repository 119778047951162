.ComparisonPanel{
  @include flex();
  width: 100%;

  @include responsive($tabletSmall, max){
    flex-direction: column;
  }

  & + .ComparisonPanel{
    @include responsive($tabletSmall, max){
      margin-top: 3em;
    }

    @include responsive($tabletSmall){
      margin-top: 1em;
    }
  }

  .comparison-card{
    @include flex(flex-start, center);
    flex-direction: column;
    color: var(--text-green);
    border-radius: 10px;
    flex: 1;

    @include responsive($tabletSmall, max){
      padding: 1.5em;
    }
    
    @include responsive($tabletSmall){
      @include font-vw(20);
      padding: 1em 1.5em;
    }

    .ComparisonTitle{
      @include responsive($tabletSmall, max){
        margin-bottom: 0.5em;
      }

      @include responsive($tabletSmall){
        display: none;
      }
    }
  }

  .arrow{
    @include flex(center, center);
    align-self: center;
    position: relative;
    height: get-vw(80);
    width: get-vw(80);
    border-radius: 50%;
    background: var(--main-green);
    border: 8px solid var(--grey-l7);
    
    @include responsive($tabletSmall, max){
      min-height: 50px;
      min-width: 50px;
      margin: -1em 0;
    }
    
    @include responsive($tabletSmall){
      margin: 0 -0.5em;
    }

    .Icon[data-name="arrow"]{
      @include responsive($tabletSmall, max){
        transform: rotate(-90deg);
      }

      @include responsive($tabletSmall){
        transform: rotate(-180deg);
      }

      svg > *{
        stroke: var(--white);
      }
    }
  }
}