.Act{
  // For self + pro
  &.--self,
  &.--pro{
    .sticky{
      > div{
        @include flex(center, center);
        height: 100%;
      }
    }
  }

  // For self
  &.--self{
    padding-top: 0;

    .head{
      height: 200vh;
      min-height: 1000px;
    }

    .sticky{
      position: sticky;
      top: 0;
      // padding: 30vh 0;
      height: 100vh; // fallback for JS load
      height: var(--vh);
    }

    .keywords{
      @include cover();
      
      // @include responsive($tabletSmall){
      //   top: 30%;
      // }
    }

    .Keyword{
      position: absolute;
      opacity: 0;

      &:first-child{
        left: 0;

        @include responsive($tabletSmall, max){
          top: 5%;
        }

        @include responsive($tabletSmall){
          top: 10%;
        }

        // @include responsive($tabletSmall){
        //   top: 0;
        // }
      }

      &:nth-child(2){
        right: 0;

        @include responsive($tabletSmall, max){
          top: 15%;
        }

        // @include responsive($tabletSmall){
        //   top: 0; 
        // }

        @include responsive($tabletSmall){
          top: 20%;
        }
      }

      &:nth-child(3){
        top: 30%; left: 10%;

        @include responsive($tabletSmall){
          top: 80%; left: space(2);
        }
      }

      &:nth-child(4){
        @include responsive($tabletSmall, max){
          top: 60%; right: 10%;
        }

        @include responsive($tabletSmall){
          top: 60%; left: 45%;
        }
      }

      &:nth-child(5){
        right: 0;

        @include responsive($tabletSmall, max){
          top: 80%;  
        }

        @include responsive($tabletSmall){
          top: 70%;
        }
      }
    }
  }

  // For pro
  &.--pro{
    padding-top: 0;

    .head{
      height: 100vh; // fallback for JS load
      height: var(--vh);
    }

    .sticky{
      height: 100%;
    }

    .gallery{
      @include cover();
      z-index: -1;

      .visual__container{
        @include imgRatio(400, 500);
        min-width: 200px;
        width: get-vw(400);
        clip-path: inset(0% 0% 0% round 0.625em 0.625em 0.625em);
        position: absolute;

        &:first-child{
          top: 0; left: 0;
        }

        &:nth-child(2){
          top: 0; left: 60%;
        }

        &:nth-child(3){
          top: 50%; left: 45%;
        }
      }
    }

    .description{
      @include responsive($tabletSmall){
        margin-top: get-vw(100);
      }
    }

    .Content{
      margin-top: 3em;

      @include responsive($tabletSmall){
        margin-top: get-vw(70);
      }
    }
  }

  // For patients
  &.--patients{
    padding: 0 0 5em;

    @include responsive($tabletSmall){
      padding-bottom: get-vw(200);
    }

    h2{
      @include responsive($tabletSmall, max){
        padding: 2em 0;
      }

      @include responsive($tabletSmall){
        padding: get-vw(80) 0 get-vw(150);
      }
    }
  }

  .head{
    h2{
      text-align: center;
      
      @include responsive($tabletSmall){
        max-width: space(30);
        margin: 0 auto;
      }
    }
  }

  .description{
    @include flex();

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    .headline{
      @include responsive($tabletSmall, max){
        margin-bottom: 2em;
      }

      @include responsive($tabletSmall){
        margin-left: space(3);
      }
    }

    .content{
      @include responsive($tabletSmall){
        max-width: space(22);
        margin-left: auto;
      }

      h3{
        color: var(--green-d8);
      }
    }
  }
}
