.BlogPosts{
  @include responsive($tabletSmall, max){
    padding-top: 50px;
  }

  @include responsive($tabletSmall){
    padding-top: get-vw(50);
  }

  #results{
    display: grid;

    @include responsive($tabletSmall, max){
      grid-gap: 2em;
    }

    @include responsive($tabletSmall){
      grid-template-columns: repeat(3, 1fr);
      grid-gap: get-vw(30);
    }

    .PostCard{
      @for $i from 1 through 100 {
        &:nth-child(#{$i}) {
          order: #{$i};
        }
      }

      &.--featured{
        order: 0;
      }
    }

    .OfferCard{
      @for $i from 1 through 10 {
         &.--#{$i} {
          order: #{$i + 5};
        }
      }

      @include responsive($tablet){
        min-height: 300px;
        height: auto;
      }
    }

    .SolutionCard{
      @for $i from 1 through 10 {
        &.--#{$i} {
          order: #{$i + 10};
        }
      }
    }
  }
}