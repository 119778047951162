.SolutionPreview{
  @include flex(flex-start);
  flex-direction: column;
  padding: 0 space(1);

  @include responsive($tabletSmall){
    align-items: center;
  }

  & + .SolutionPreview{
    margin-top: 5em;
  }

  &:nth-child(odd){
    @include responsive($tabletSmall){
      flex-direction: row;
    }

    > .inner{
      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  }

  &:nth-child(even){
    @include responsive($tabletSmall){
      flex-direction: row-reverse;
    }

    > .inner{
      @include responsive($tabletSmall){
        margin-right: auto;
      }
    }

  }

  > .visual__container,
  > .inner{
    width: 100%;
    
    @include responsive($tabletSmall){
      max-width: get-vw(540);
    }
  }
  
  > .visual__container{
    @include imgRatio(540, 650, var(--green-l7));
    clip-path: inset(0% 0% 0% round 1.250em 1.250em 1.250em);

    @include responsive($tabletSmall, max){
      margin-bottom: 2em;
      max-width: 400px;
    }
  }

  > .inner{
    @include flex(flex-start);
    flex-direction: column;

    .Tag{
      margin-bottom: 2em;

      @include responsive($tabletSmall){
        margin-bottom: get-vw(25);
      }

      .visual__container{
        @include responsive($tabletSmall, max){
          min-width: 20px;
        }
      }
    }

    p, .Btn{
      margin-top: 1.4em;
    }

    p{
      color: var(--text-green);
    }
  }
}