.Skill{
  @include flex(flex-start flex-start);
  padding: 20px 25px;
  background: var(--green-l8);
  border-radius: var(--radius);

  > .check{
    background: var(--green-l7);
  }

  > span{
    flex: 1;
    padding-left: 1em;
  }
}