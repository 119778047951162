.Form {
  .is--error {
    color: $error-color;
  }

  .row {
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // grid-gap: 30px;

    // > * {
    //   @include responsive($tabletSmall, max){
    //     grid-column: span 2;
    //   }

    //   &:not(.--full){
    //     @include responsive($tabletSmall){
    //       grid-column: span 1;
    //     }
    //   }

    //   &.--full {
    //     @include responsive($tabletSmall) {
    //       grid-column: span 2;    
    //     }
    //   }
    // }
  }

  label:not(.Checkbox):not(.CheckboxWithIcon) {
    display: block;
    margin-bottom: 0.5em;
    color: var(--white);

    sup{
      color: var(--main-green);
    }
  }

  &__group {
    position: relative;
    width: 100%;

    span.is--error {
      position: absolute;
      top: 100%; left: 0;
      font-size: 0.8em;
      padding-top: 2px;
    }

    &.--message{
      @include flex();
      flex-direction: column;

      > textarea{
        flex: 1;
      }
    }

    &.--search{
      input[type="search"]::-webkit-search-decoration,
      input[type="search"]::-webkit-search-cancel-button,
      input[type="search"]::-webkit-search-results-button,
      input[type="search"]::-webkit-search-results-decoration {
        display: none;
      }

      .Form__control{
        background: var(--grey-l7);
        border: none;
        padding: 0 3em 0 1.25em;
        color: #3d3d3d;

        &::placeholder{
          color: #7b7b7b;
        }
      }
    }

    .select-container{
      position: relative;

      .Icon[data-name="chevron"]{
        position: absolute;
        top: 50%; right: 0;
        transform: translate(-50%, -50%);
        pointer-events: none;

        svg > *{
          stroke: var(--white);
        }
      }
    }
  }

  &__control {
    width: 100%;
    border-radius: var(--radius);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--white);
    font: 400 16px $nm;
    min-height: 4em;
    padding: 0 1.250em;
    transition: border-color 200ms var(--ease-out-cubic);

    @include responsive($tabletSmall){
      @include font-vw(16);
    }

    &:focus,
    &:active{
      border-color: rgba(255, 255, 255, 0.35);
    }

    .is--error &{
      border-color: $error-color;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  select.Form__control {
    padding-top: 1.25em;
    padding-right: 3em;
  }

  textarea.Form__control {
    padding: 1.35em 1.25em;
  }

  // &__actions {
  //   .Btn {
  //     margin-top: 0;
  //   }
  // }

  &__output {
    color: $valid-color;

    p:not(:empty) {
      margin-top: 20px;
    }
  }
}
