.PageHeaderPopp{
  > .visual__container{
    @include imgRatio(1440, 500);

    &:after{
      @include content();
      @include cover();
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  > .wrapper{
    padding: 100px 0;
    overflow: hidden;

    .inner{
      @include responsive($tabletSmall){
        max-width: space(22);
      }
    }

    .wswyg--content{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(30);
      }
    }
  }

  .container{
    // Icone
    > .icon{
      @include imgRatio(354, 373);
      width: get-vw(354);
      position: absolute;
      top: -8%; right: -3%;

      svg{
        @include cover();
      }
    }
  }
}
