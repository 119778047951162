.OurSolutionsPresentation{
  background: var(--green-l8);

  .IconsMarquee{
    margin-bottom: 3em;

    @include responsive($tabletSmall){
      margin-bottom: get-vw(120);
    }
  }

  h2{
    color: var(--green-d2);
  }
}