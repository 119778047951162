.AboutAccompaniment{
  > .container{
    @include flex();

    @include responsive($tabletSmall, max){
      flex-direction: column
    }
  }

  .head{
    width: 100%;
    
    @include responsive($tabletSmall){
      max-width: space(20);
    }

    > p{
      color: var(--text-green);
      
      @include responsive($tabletSmall, max){
        margin-bottom: 1em;
      }

      @include responsive($tabletSmall){
        margin-bottom: get-vw(20);  
      }
    }

    nav{
      @include flex(flex-start);
      
      @include responsive($tabletSmall, max){
        flex-direction: column;
        padding-bottom: 4em;
      }

      @include responsive($tabletSmall){
        flex-direction: column;
      }

      > button{
        @include flex(flex-start);
        font: 400 35px $nm;
        position: relative;

        @include responsive($tabletSmall){
          @include font-vw(65);
        }

        @include responsive($deskXXL){
          @include font-vw(50);
        }

        & + button{
          @include responsive($tabletSmall, max){
            margin-top: 0.5em;
          }

          @include responsive($tabletSmall){
            margin-top: get-vw(10);
          }
        }

        &:last-child{
          @include responsive($tabletSmall, max){
            margin-right: 1.25em;
          }
        }

        @include noTouchHover() {
          .Icon[data-name="arrow-top"]{
            transform: translate(0, 0);
            opacity: 1;
          }
        }

        span{
          background-image: linear-gradient(var(--main-green), var(--main-green));
          background-position: 0% 100%;
          background-repeat: no-repeat;
          background-size: 0% 2px;
          transition: background 550ms var(--ease-out-quart);
        }

        .Icon[data-name="arrow-top"]{
          display: inline-block;
          margin-top: 0.2em;
          margin-left: 0.2em;
          opacity: 0;
          transform: translate(-50%, 20%);
          transition: transform 550ms var(--ease-out-quart),
                      opacity 550ms var(--ease-out-quart);
        }

        &.--active{
          color: var(--main-green);
          
          span{
            background-size: 100% 2px;
          }

          @include noTouchHover() {
            .Icon[data-name="arrow-top"]{
              opacity: 0;
            }
          }
        }
      }
    }
  }

  .tabs{
    @include responsive($tabletSmall, max){
      overflow: hidden;
    }

    @include responsive($tabletSmall){
      margin-left: auto;
      margin-right: space(2);
    }
  }
}