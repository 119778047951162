.BlockOffer{
  &.--cart,
  &.--club{
    background: var(--white);

    .swiper{
      &-wrapper{
        order: 1;
      }

      &-navigation{
        order: 2;
        justify-content: center;

        @include responsive($tabletSmall, max){
          margin-top: 2em;
        }

        @include responsive($tabletSmall){
          margin-top: get-vw(50);
        }

        > *{
          background: var(--green-l8);

          svg > *{ stroke: var(--main-green) }
        }
      }
    }
  }

  // Notre offre à la carte
  &.--cart{
    .swiper{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(70);
      }

      &-slide{
        @include responsive($tabletSmall, max){
          max-width: 90%;
        }
        
        @include responsive($tabletSmall){
          max-width: get-vw(570);
        }

        & + .swiper-slide{
          margin-left: 15px;

          @include responsive($tabletSmall){
            margin-left: get-vw(30);
          }
        }
      }
    }
  }

  // Notre offre club
  &.--club{
    .swiper-wrapper{
      margin: 5em auto 0;
      
      @include responsive($tabletSmall){
        cursor: initial;
        max-width: get-vw(1140);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: space(1);
        grid-template-areas:
            "A B"
            "A B"
            "C B"
            "C D"
            "C D";
            // "E F"
            // "E F"
            // "G F"
            // "G H"
            // "G H";
      }

      .swiper-navigation{
        @include responsive($tabletSmall){
          display: none;
        }
      }

      .swiper-slide{
        @include flex(flex-start);
        flex-direction: column;
        border-radius: var(--radius);
        background: var(--green-l8);
        padding: 40px;
        
        @include responsive($tabletSmall, max){
          max-width: 90%;
        }

        @include responsive($tabletSmall){
          grid-column: span 1;
        }

        &:first-child { 
          @include responsive($tabletSmall){
            grid-area: A;
          }
        }

        &:nth-child(2){ 
          @include responsive($tabletSmall){
            grid-area: B;
          }
        }
        
        &:nth-child(3){
          @include responsive($tabletSmall){
            grid-area: C;
          }
        }
        
        &:nth-child(4){ 
          @include responsive($tabletSmall){
            grid-area: D;
          }
        }
        
        // &:nth-child(5){ grid-area: E }
        
        // &:nth-child(6){ grid-area: F }
        
        // &:nth-child(7){ grid-area: G }
        
        // &:nth-child(8){ grid-area: H }

        p{
          padding-bottom: 1.2em;
        }

        .check{
          background: var(--green-l7);
          height: get-vw(57);
          width: get-vw(57);
          margin-top: auto;
        }
      }
    }

    .swiper-navigation{
      @include responsive($tabletSmall){
        display: none;
      }
    }

    .more{
      *{
        font: 400 14px $nm;
        text-align: center;
        line-height: 1.6;
        max-width: 750px;
        margin: 4.5em auto 0;
      }
    }
  }

  // Notre offre libérale
  &.--liberal{
    padding-top: 2em;
    background: var(--green-d8-l);

    .Banner{
      clip-path: inset(0 2em 0 round var(--radius) var(--radius) var(--radius));
    }

    .head{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(100);
      }

      .subtitle{
        color: var(--white);
      }

      .visual__container{
        background: #0D3636;
        border-radius: var(--radius);
      }

      h2{
        color: var(--main-green);
        text-align: center;
        
        @include responsive($tabletSmall){
          max-width: 50%;
        }
      }

      p{
        font: 400 16px $nm;
        line-height: 1.8;
        color: var(--green-l7);
        margin: 1.8em 0 auto;

        @include responsive($tabletSmall, max){
          max-width: none;
        }

        @include responsive($tabletSmall){
          @include font-vw(16);
        }
      }

      .Btn{
        background: var(--white);
        color: var(--main-green);

        svg > *{ stroke: var(--main-green) }
      }
    }
  }

  .head{
    @include flex(center, center);
    flex-direction: column;

    .visual__container{
      @include imgRatio(150, 150);
      min-width: 100px;
      max-width: get-vw(150);
      margin-bottom: 3em;
    }

    .subtitle{
      color: var(--green-l1);

      @include responsive($tabletSmall, max){
        margin-bottom: 1.25em;
      }

      @include responsive($tabletSmall){
        margin-bottom: 0.5em;
      }

      .--liberal &{
        color: var(--white);
      }
    }

    > h2{
      color: var(--black);
    }

    p{
      font: 400 20px $nm;
      line-height: 1.65;
      color: var(--black);
      text-align: center;
      max-width: get-vw(900);
      margin: 1.2em 0 auto;

      @include responsive($tabletSmall){
        @include font-vw(20);
      }
    }

    .Btn{
      margin-top: 2em;
    }
  }
}
