a:not([class]) {
  color: $links-color;
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

// a[class] {
//   color: inherit;
//   text-decoration: none;
// }

.Anchors {
  &__nav {
    @include flex();
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: -10px;
  }

  &__item {
    margin-right: 20px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.Link {
  position: relative;
  
  span{
    display: inline;
    // background-image: linear-gradient(var(--base), var(--base));
    background-image: linear-gradient(var(--text), var(--text));
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    transition: background-size 600ms var(--ease-in-out-circ);
  }

  @include noTouchHover() {
    span{
      background-size: 100% 1px;
      background-position: 0% 100%;
    }
  }

  // With single line (before)
  // &:not(.--double){
  //   &:before{
  //     @include content();
  //     position: absolute;
  //     top: 100%; left: 0;
  //     width: 100%; height: 1px;
  //     background: var(--text);
  //     transform: scaleX(0);
  //     transform-origin: 100% 100%;
  //     transition: transform 750ms var(--ease-in-out-quart);
  //   }

  //   @include noTouchHover() {
  //     &:before{
  //       transform: scaleX(1);
  //       transform-origin: 0% 0%;
  //     }
  //   }
  // }

  // With double lines (before/after)
  &.--double{
    &:after,
    &:before {
      @include content();
      position: absolute;
      top: 100%; left: 0;
      width: 100%; height: 1px;
      background: var(--text);
      transform-origin: 100% 50%;
      transition: transform 400ms var(--ease-in-out-quart) 50ms;
    }

    &:before {
      transform: scaleX(0);
      transform-origin: 0% 50%;
      transition-delay: 0;
    }

    @include noTouchHover() {
      &:before {
        transform: scaleX(1);
        transition-delay: 250ms;
      }

      &:after {
        transform: scaleX(0);
        transition-delay: 0;
      }
    }
  }

  &.--icon{
    @include flex(center);
    position: relative; 
    z-index: 1;

    > span{
      margin-right: 0.25em;
    }

    > .Icon{
      transform: translateY(10%);
    }
  }
}
