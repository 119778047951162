.Vision{
  position: relative;
  padding-bottom: 0;

  @include responsive($tabletSmall, max){
    height: 250vh;
  }

  @include responsive($tabletSmall){
    height: 350vh;
  }

  > .container{
    height: 100%;
  }

  .head{
    position: relative;
    z-index: 1;
   
    @include responsive($tabletSmall){
      max-width: space(23);
      margin-left: space(1);
    }

    h2{
      color: var(--green-d8);
      margin-bottom: 2em;

      @include responsive($tabletSmall){
        margin-bottom: get-vw(40);
      }
    }
  }

  .circles{
    @include flex(center, center);
    width: 100%;
    position: sticky;
    top: 50%;
    transform: translateY(-50%);
    
    @include responsive($tabletSmall, max){
      height: 20%;
    }
    
    @include responsive($tabletSmall){
      height: get-vw(600);
    }

  }

  .circle{
    &.--left,
    &.--right{
      border-radius: 50%;
      position: absolute;
      height: get-vw(1000);
      width: get-vw(1000);

      @include responsive($tabletSmall, max){
        left: 50%;
      }
      
      @include responsive($tabletSmall){
        height: get-vw(600);
        width: get-vw(600);
      }

      > span{
        font: 400 4.5vw $nm;
        color: var(--white);
        position: absolute;
        top: 50%;
        opacity: 0;

        @include responsive($tabletSmall, max){
          text-align: center;
          width: 70%;
        }

        @include responsive($tabletSmall){
          @include font-vw(30);
        }

        @include responsive($deskXXL){
          @include font-vw(20);
        }
      }
    }

    &.--left{
      background: var(--green-d2);
      z-index: 1;
      mix-blend-mode: difference;
      
      @include responsive($tabletSmall, max){
        left: 50%;
        transform: translate(-50%, 0);
      }
      
      @include responsive($tabletSmall){
        left: 12%;
      }

      > span:first-child{
        @include responsive($tabletSmall, max){
          
        }

        @include responsive($tabletSmall){
          max-width: get-vw(270);
          left: 0;
          transform: translate(20%, -50%);
        }
      }

      > span:nth-child(2){
        @include responsive($tabletSmall, max){
          top: 0; left: 50%;
          transform: translate(-50%, 100%);
          font-size: 3vw;
          max-width: 50%;
          color: var(--black);
        }

        @include responsive($tabletSmall){
          max-width: get-vw(170);
          color: var(--black);
          text-align: center;
          right: 0;
          transform: translate(get-vw(-35), -50%);
        }
      }
    }

    &.--right{
      background: var(--main-green);
      z-index: 0;
      transform: scale(5);

      @include responsive($tabletSmall){
        right: 12%;
      }

      > span{
        @include responsive($tabletSmall, max){
          // max-width: get-vw(250);
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        @include responsive($tabletSmall){
          max-width: get-vw(250);
          position: absolute;
          right: 0;
          transform: translate(-20%, -50%);
        }
      }
    }
  }
}
