.RelatedSolutions{
  .head{
    @include flex(center, center);
    flex-direction: column;
    margin-bottom: 4em;

    @include responsive($tabletSmall){
      position: sticky;
      top: get-vw(100);
      margin-bottom: get-vw(100);
    }

    h2{
      text-align: center;

      @include responsive($tabletSmall){
        @include font-vw(50);
      }

      @include responsive($deskXXL){
        @include font-vw(35);
      }
    }

    .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(30);
      }
    }
  }

  .container > .--oh{
    @include responsive($tabletSmall){
      padding-top: 2em;
    }
  }

  .swiper{
    @include responsive($tabletSmall){
      margin: 0 space(2);
    }

    &-wrapper{
      @include responsive($tabletSmall){
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: space(1);
      }
    }

    &-slide{
      @include responsive($tabletSmall, max){
        max-width: 360px;
      }

      @include responsive($mobile, max){
        max-width: 90%;
      }

      @include responsive($tabletSmall){
        margin-top: -1.5em !important;
      }

      &:nth-child(3n + 2){
        @include responsive($tabletSmall){
          margin-top: 1.5em !important;
        }
      }
    }
  }
}
