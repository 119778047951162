.swiper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;

  &-wrapper {
    order: 2;
    cursor: grab;

    .swiper-container:not(.swiper-container-free-mode) &{
      transition: transform 600ms $ease!important;
    }

    .swiper-container.is--dragging &{
      transition: none!important;
    }
  }

  &-slide {
    height: auto;
    transition: transform 600ms $ease!important;

    // .is--dragging & {
    //   transform: scale(0.85)!important;
    // }
  }

  &-navigation {
    order: 1;
    // margin-bottom: 20px;
    @include flex(center, flex-end);

    > * {
      border: 0;
      @include flex(center, center);
      cursor: pointer;
      height: get-vw(60);
      width: get-vw(60);
      min-height: 45px;
      min-width: 45px;
      transition: opacity 240ms $easeOutQuad;
      border-radius: 50%;
      background: var(--main-green);

      &.swiper-button-disabled {
        opacity: 0.2;
        pointer-events: none;
      }

      &+* { margin-left: 0.8em; }

      .Icon[data-name="arrow"]{
        svg > *{ stroke: var(--white) }
      }

      &.next{
        .Icon[data-name="arrow"]{
          transform: scaleX(-1);
        }
      }
    }
  }

  &-pagination {
    order: 3;
    position: relative!important;
    margin-top: 20px!important;
    bottom: 0px!important;
    @include flex(center, flex-end);

    > * {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: $base;
      cursor: pointer;
      transition: all 240ms $easeOutQuad;

      .is--dark & {
        background: var(--grey-l);
      }

      @include noTouchHover() {
        transform: scale(1.1);
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.is--active {
        background: $links-color;
      }
    }
  }

  &-scrollbar {
    order: 4;
    position: relative!important;
    left: 0%!important;
    width: 100%!important;
    margin-top: 20px;
    height: 2px!important;
    background: var(--grey-l);

    .is--dark & {
      background: var(--black);
    }

    &-drag {
      position: absolute;
      top: 0px;
      background: $base;
      border-radius: 4px;
      cursor: pointer;

      .is--dark & {
        background: var(--grey-l);
      }
    }
  }
}
