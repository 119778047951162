.Sharing{
  > ul{
    @include flex();
    gap: 0.5em;

    a, button{
      @include imgRatio(40, 40);
      @include flex(center, center);
      min-width: 30px;
      background: var(--white);
      position: relative;

      @include noTouchHover(){
        .pop{
          opacity: 1;
          transform: translate(0, 0);
        }
      }

      .pop{
        font: 500 13px $nm;
        pointer-events: none;
        position: absolute;
        bottom: 120%;
        transform: translate(0, 10%);
        opacity: 0;
        white-space: nowrap;
        background: var(--black);
        color: var(--white);
        padding: 6px;
        border-radius: 4px;
        transition: transform 350ms var(--ease-in-out-circ),
                    opacity 350ms var(--ease-in-out-circ);

        @include responsive($tabletSmall, max){
          left: -5%;
        }

        @include responsive($tabletSmall){
          @include font-vw(12);
          right: -5%;
        }

        @include responsive($deskXL){
          font-size: 12px;
        }

        .Icon[data-name="triangle-small"]{
          position: absolute;
          top: 75%; 
          min-width: 20px;

          @include responsive($tabletSmall, max){
            left: 5%;
          }

          @include responsive($tabletSmall){
            right: 5%;
          }
        }
      }

      > .Icon{
        pointer-events: none;
      }
    }

    button{
      > .Icon{
        transition: transform 550ms var(--ease-in-out-circ),
                    opacity 550ms var(--ease-in-out-circ);
      }

      .Icon[data-name="tick"]{
        opacity: 0; 
        transform: translate(-50%, -50%) scale(0.6);
        position: absolute;
        top: 50%; left: 50%;
      }

      .pop{
        > span{
          transition: opacity 550ms var(--ease-in-out-circ);

          &:nth-child(2){
            position: absolute;
            top: 23%; left: 8%;
            white-space: nowrap;
            opacity: 0;
          }
        }
      }

      &.--copied{
        .Icon[data-name="url"]{
          opacity: 0; 
          transform: scale(0.6);
        }

        .Icon[data-name="tick"]{
          opacity: 1; 
          transform: translate(-50%, -50%) scale(1);
        }

        .pop{
          > span{
            &:first-child{
              opacity: 0;
            }

            &:nth-child(2){
              opacity: 1;
            }
          }
        }
      }
    }

    .only-mobile{
      @include responsive($tabletSmall){
        display: none;
      }
    }

    .Icon[data-name="facebook"],
    .Icon[data-name="twitter"],
    .Icon[data-name="whatsapp"],
    .Icon[data-name="linkedin"]{
      svg > *{
        fill: var(--main-green);
      }
    }
  }
}