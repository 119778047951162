.SingleArticle.--gutenberg .wswyg--content, .wp-block-post-content {
    
  h1, h2, h3 {
      color: $base;
      line-height: 1.2;
      font-weight: 300;
  }

  h1 {
      font-size: 46px!important;

      @include responsive($tabletSmall) {
        @include font-vw(46, important);
      }
  }

  h2 {
      font-size: 38px!important;

      @include responsive($tabletSmall) {
          @include font-vw(38, important);
      }

      strong {
          color: $green-l1;
      }
  }

  h3 {
      font-size: 29px!important;

      @include responsive($tabletSmall) {
        @include font-vw(29, important);
      }
  }

  .Btn {
    text-decoration: none!important;
  }
  
  > p {
    max-width: 90%;

    @include responsive($tabletSmall) {
      max-width: 80%;
    }
  }

  > ul {
    padding-left: 20px;

    @include responsive($tabletSmall) {
      padding-left: get-vw(40);
    }
  }

  .wp-block-image, .wp-block-video {

    img, video {
      border-radius: 10px;

      @include responsive($tabletSmall) {
        border-radius: get-vw(10);
      }
    }

    .wp-element-caption {
      text-align: center;
      font-size: 11px;

      @include responsive($tabletSmall) {
        @include font-vw(11);
      }
    }
  }

  .wp-block-video {
    .plyr__container {
      margin: 0!important;
      height: auto!important;
      border-radius: 10px;
      aspect-ratio: 1080/610;
      min-height: 0;
  
      @include responsive($tabletSmall, max) {
          border-radius: get-vw(10);
      }

      .plyr__cover {
        display: none;
      }

      .plyr__play {
          transform: translate(-50%, -50%);
          min-width: 80px;
          width: 80px;
          &:before { background: $white; }
          .Icon {
              min-width: 15%;
              width: 15%;
              svg > * { fill: $green-l1; }
          }
      }
    }
  }
}

.SingleArticle.--gutenberg .wswyg--content {

	> * + * {
		margin-top: 1.5em;
	}

  > div {
    margin-top: 60px;
    margin-bottom: 60px;

    @include responsive($tabletSmall) {
      margin-top: get-vw(100);
      margin-bottom: get-vw(100);
    }
  }
}