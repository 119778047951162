.AccompanimentTab{
  margin-bottom: 3em;
  transition: transform 550ms var(--ease-in-out-quart) 100ms,
              opacity 550ms var(--ease-in-out-quart) 100ms;

  &:not(.--active){
    opacity: 0;
    pointer-events: none;
    transform: translate(30%, 10%) scale(0.6) rotate(3deg);
    transition-delay: 0ms;
  }

  &:not(:first-child){
    @include cover();
  }

  // Adapt styles for the first slide 
  &:nth-child(even) {
    .swiper-slide{
      &:nth-child(3n + 1){
        background: var(--green-d8);

        .head > span{ color: var(--text-green) }
        .content > span{ color: var(--white) }
        .content > p{ color: var(--main-green) }
      }
    }
  }

  .swiper {
    width: get-vw(420);
    height: get-vw(550);
    min-width: 270px;
    min-height: 400px;

    &.swiper-3d .swiper-slide-shadow{
      background: none;
    }

    &-slide{
      background: var(--green-d8);
      border-radius: 1em;

      &:nth-child(3n + 1){
        background: var(--green-l8);

        .head > span{ color: var(--green-l1) }
        .content > span{ color: var(--green-d8) }
        .content > p{ color: var(--text-green) }
      }
    
      &:nth-child(3n + 2){
        background: var(--green-l7);

        .head > span{ color: var(--black) }
        .content > span{ color: var(--main-green) }
        .content > p{ color: var(--text-green) }
      }

      &:nth-child(3n + 3){
        background: var(--green-d2);

        .head > span{ color: var(--green-l1) }
        .content > span{ color: var(--white) }
        .content > p{ color: var(--green-l7) }
      }

      .inner{
        @include flex();
        flex-direction: column;
        height: 100%;

        @include responsive($tabletSmall, max){
          padding: 1.2em;
        }
        
        @include responsive($tabletSmall){
          padding: 3em;
        }
      }

      .head{
        padding-bottom: 3em;
      }

      .content{
        margin-top: auto;

        > span{
          font: 400 20px $nm;

          @include responsive($tabletSmall){
            @include font-vw(28);
          }
        }

        > p{
          
          @include responsive($tabletSmall, max){
            margin-top: 1em;
            font-size: 13px;
          }
          
          @include responsive($tabletSmall){
            margin-top: get-vw(20);
            @include font-vw(15);
          }
        }
      }
    }

    &-navigation{
      margin-top: 1em;
      justify-content: center;

      > *{
        background: none;
        border: 1px solid var(--grey-l9);

        .Icon[data-name="arrow"]{
          svg > *{ stroke: var(--main-green) }
        }
      }
    }
  }
}