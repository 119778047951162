.PageHeaderBlog{
  padding-top: 200px;
  padding-bottom: 100px;

  @include responsive($tabletSmall){
    padding-top: 10em;
    padding-bottom: 5em;
  }

  > .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      align-items: flex-end;
    }

    > *{
      width: 100%;
    }
  }

  h1{
    color: var(--black);

    @include responsive($tabletSmall){
      max-width: space(24);
    }
  }

  .Form{
    position: relative;

    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      max-width: space(16);
      margin-left: auto;
    }

    // Hiding (for accessibility)
    &__actions{
      position: absolute;
      height: 100%;
      display: flex;
      top: 0; right: 0.85em;

      > button{
        @include noTouchHover() {
          .Icon[data-name="search"]{
            transform: scale(0.9);
          }
        }
      }

      .Icon[data-name="search"]{
        transition: transform 450ms var(--ease-in-out-quart);
      }
    }
  }
}