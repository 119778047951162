.Testimonials{
  background: $grey-l8;

  > .container{
    @include flex();
    flex-direction: column;

    > .Btn{
      margin-top: 5em;
      align-self: center;

      @include responsive($tabletSmall){
        margin-top: get-vw(80);
      }
    }
  }

  .head{
    @include flex();
    margin-bottom: 3em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      margin-bottom: get-vw(80);
    }

    h2{
      @include responsive($tabletSmall){
        max-width: space(18)
      }
    }

    nav{
      @include responsive($tabletSmall, max){
        margin-top: 3em;
      }

      @include responsive($tabletSmall){
        max-width: space(5);
        margin-left: auto;
        align-self: flex-end
      }
    }
  }

  .swiper{
    &-slide{


      @include responsive($tabletSmall){
        max-width: get-vw(510);
        // max-width: 510px;
      }
    }
  }
}