.PostCard{
  border-radius: 10px;
  overflow: hidden;
  background: var(--white);

  @include noTouchHover() {
    .head{
      .visual__container{
        .visual{
          transform: scale(1.05);
        }
      }
    }
  }

  &.--featured{
    position: relative;

    @include responsive($tabletSmall, max){
      background: var(--green-l8);
    }
    
    @include responsive($tabletSmall){
      grid-column: 1/3;
      background: none;
    }

    &, .inner, .head, .head .visual__container{
      @include responsive($tabletSmall){
        height: 100%;
      }
    }

    .inner{
      @include responsive($tabletSmall){
        padding: 0;
      }
    }

    .head{
      @include responsive($tabletSmall){
        margin-bottom: 0;
      }

      .visual__container{
        @include responsive($tabletSmall, max){
          border-radius: none;
        }

        @include responsive($tabletSmall){
          @include imgRatio(870, 510);
        }

        &:after{
          @include responsive($tabletSmall){
            @include content();
            @include cover();
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
          }
        }
      }
    }

    .content,
    .head .categories{
      @include responsive($tabletSmall){
        padding: get-vw(40);
      }
    }

    .content{
      @include responsive($tabletSmall){
        position: absolute;
        bottom: 0; left: 0;
        max-width: get-vw(630);
      }

      .--title, p{
        @include responsive($tabletSmall){
          color: var(--white);
        }
      }
    }
  }

  .head{
    position: relative;
    margin-bottom: 1em;

    .categories{
      @include flex();
      gap: 5px;
      flex-wrap: wrap;
      position: absolute;
      top: 0; left: 0;
      
      @include responsive($tabletSmall, max){
        padding: 15px;
      }

      @include responsive($tabletSmall){
        padding: get-vw(15);
      }
    }
  
    .visual__container{
      @include imgRatio(380, 230);
      border-radius: 5px;
      
      .visual{
        transition: transform 350ms var(--ease-in-out-quad);
      }
    }
  }

  .inner{
    @include flex();
    flex-direction: column;
    height: 100%;
    padding: 20px;
  }

  .content{
    @include flex();
    flex-direction: column;
    flex: 1;

    .--title{
      font: 400 30px $nm;
      margin-bottom: 0.6em;
      color: var(--black);
  
      @include responsive($tabletSmall){
        @include font-vw(30);
      }
  
      @include responsive($deskXXL){
        @include font-vw(20);
      }
    }

    p{
      margin-bottom: 1em;
      color: var(--text-green);
    }
  
    .Btn{
      margin-top: auto;
      align-self: start;
    }
  }
}
