.Tag{
  font: 700 13px $nm;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  color: var(--main-green);
  background: var(--green-l8);
  padding: 0.8em 1.6em;
  border-radius: 20em;

  @include responsive($tabletSmall){
    @include font-vw(13);
  }

  .visual__container{
    @include imgRatio(20, 20);
    margin-right: 0.5em;
    width: get-vw(20);

    @include responsive($tabletSmall){
      min-width: 10px;
    }
  }
}