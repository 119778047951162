.PageHeaderSolution{

  > .container{
    padding-top: 3em;
  
    @include responsive($tabletSmall){
      padding-top: get-vw(30);
    }
  }

  > .visual__container{
    @include imgRatio(1440, 500);

    &:after{
      @include content();
      @include cover();
      background: var(--black);
      opacity: 0.35;
    }
  }

  .type{
    @include flex(center);
    margin-bottom: 0.5em;

    .visual__container{
      @include imgRatio(100, 100);
      width: get-vw(100);
    }

    .Tag{
      margin-left: 1.8em;
    }
  }

  .title{
    @include flex(flex-start);
    margin-bottom: 2em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      align-items: flex-end;
    }

    h1{
      width: 100%;
      
      @include responsive($tabletSmall){
        max-width: space(28);
      }
    }

    .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        margin-left: auto;
        margin-bottom: 1em;
      }
    }
  }

  .back{
    font: 500 17px $nm;
    display: inline-flex;
    margin-bottom: 2.5em;

    @include responsive($tabletSmall){
      @include font-vw(17);
    }

    span{
      transform: translate(0.7em, 0);
    }
  }
}