.CardTick{
  @include flex(flex-start, flex-start);
  flex-direction: column;
  padding: 2em;
  border-radius: var(--radius);
  height: get-vw(400);
  width: get-vw(500);
  min-height: 300px;
  min-width: 300px;

  &:not(.--dark){
    background: var(--green-l8);

    .wswyg--content{
      > *{
        color: var(--black);

        strong{
          color: var(--main-green);
        }
      }
    }

    .check{
      background: var(--green-l7);
    }
  }

  &.--dark{
    background: var(--green-d8);

    .wswyg--content{
      > *{
        color: #839A96;

        strong{
          color: var(--white);
        }
      }
    }

    .check{
      background: #0D3636;
    }
  }

  .wswyg--content{
    padding-bottom: 4em;

    *{
      @include responsive($tabletSmall){
        @include font-vw(25);
      }
    }
  }

  .check{
    margin-top: auto;
  }
}