.Block__gallery {
    overflow: hidden;

    .swiper-navigation {
        order: 2;
        margin-top: 24px;

        @include responsive($tabletSmall) {
            margin-top: get-vw(24);
        }

        > * {
            background: $green-l7;
    
            svg > * {
                stroke: $green-l1!important;
            }
        }
    }

    .swiper:not(.swiper-initialized) {
        .swiper-wrapper {
            gap: 30px;

            @include responsive($tabletSmall) {
                gap: get-vw(30);
            }
        }
    }

    .swiper-wrapper {
        order: 1;
    }

    .swiper-slide {
        width: auto;
        height: 500px;

        .visual__container {
            aspect-ratio: 500/580;
            height: 100%;
            width: auto;
            border-radius: 10px;
            background: $base;

            @include responsive($tabletSmall) {
                border-radius: get-vw(10);
            }

            &:after {
                display:none!important;
            }
        }
    }
}