.App {
  width: 100%;
  min-height: 100vh;
  background: var(--white);
  position: relative;
  z-index: 10;

  html:not(.no-js) body.--loading & {
    pointer-events: none;
  }

  > * {
    // background: var(--white);

    // For Page Contact transition 
    // (popup styles)
    &:first-child{
      body.--show-contact &{
        @include cover(fixed);
        z-index: 0;
      }
    }
  }
}

.container {
  width: 100%;
  padding: 0 4vw;
  position: relative;

  @include responsive($tabletSmall) {
    padding: 0 calc(100vw / 16);
  }

  &.--col-2{
    @include responsive($tabletSmall){
      padding: 0 calc(100vw/24);
    }
  }
}

section {
  padding: get-vw(150) 0;

  @include responsive($tabletSmall) {
    padding: get-vw(100) 0;
  }
}

.--content-layout {
  // Alternative :
  // title
  //        content
  &.--alt {
    .headline,
    .content {
      @include responsive($tabletSmall) {
        max-width: space(19);
      }
    }

    .content {
      @include responsive($tabletSmall) {
        margin-top: get-vw(-50);
        padding-right: space(1);
      }
    }
  }

  > .container {
    @include flex(flex-start);
    flex-direction: column;
    flex-wrap: wrap;
  }

  .headline {
    @include responsive($tabletSmall) {
      // max-width: space(14);
      max-width: space(24);
      margin-left: space(1);
    }
  }

  .content {
    @include responsive($tabletSmall, max) {
      margin-top: 2em;
    }

    @include responsive($tabletSmall) {
      max-width: space(20);
      margin-top: get-vw(70);
      margin-left: auto;
    }

    // To know if we need to display
    // the Read more button + gradient
    &.--has-read-more{
      .wswyg--content{
        height: 450px;
        overflow: hidden;

        &:after{
          @include content();
          position: absolute;
          bottom: 0; left: 0;
          height: 4em; width: 100%;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.78) 38.78%, var(--white) 100%);
        }
      }
    }

    &:not(.--has-read-more) {
      .read-more{
        display: none;
      }
    }

    // When we deploy all the text
    &.--show-more{
      
      .wswyg--content{
        height: auto;

        &:after{
          content: none;
        }
      }

      .read-more{
        span{
          &:first-child{
            transform: translate(0, -100%);
          }

          &:nth-child(2){
            transform: translate(0, 0);
          }
        }
      }
    }

    .wswyg--content{
      position: relative;

      h3, h4, h5, h6{
        color: var(--green-d8);
      }
    }

    .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(40);
      }
    }

    .read-more{
      color: var(--main-green);
      display: block;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      margin-top: 1em;

      span{
        transition: transform 250ms var(--ease-in-out-cubic);

        &:nth-child(2){
          position: absolute;
          top: 0; left: 0;
          width: 100%;
          white-space: nowrap;
          transform: translate(0, 100%);
        }
      }
    }
  }
}
