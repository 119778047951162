.Logo{
  @include imgRatio(129, 49);
  min-width: 129px;
  position: relative;

  .Header &{
    @include responsive($tablet){
      min-width: get-vw(129);
    }
  }

  svg{
    @include cover();

    > *{
      fill: $main-green;
    }
  }
}