.FaqContact{
  background: var(--green-d1);

  @include responsive($tabletSmall, max){
    clip-path: inset(0.3125em 0.25em 0.3125em round 0.625em 0.625em 0.625em);
  }
  
  @include responsive($tabletSmall){
    clip-path: inset(0.3125em space(2) 0.3125em round 0.625em 0.625em 0.625em);
  }

  &__inner{
    @include flex();

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      padding: 0 space(1);
    }
  }

  &__cta{
    @include responsive($tabletSmall, max){
      align-self: start;
      margin-top: 20px;
    }
    
    @include responsive($tabletSmall){
      margin-left: auto;
      align-self: center;
    }
  }
}