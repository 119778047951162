.Block__testimonies {
    overflow: hidden;

    .headline {
        @include flex(center,space-between);
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 24px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(24);
        }

        h2, .swiper-navigation {
            margin: 0!important;
        }
    }

    .swiper-navigation > * {
        background: $green-l7;

        svg > * {
            stroke: $green-l1!important;
        }
    }

    .swiper:not(.swiper-initialized) {
        .swiper-wrapper {
            gap: 30px;

            @include responsive($tabletSmall) {
                gap: get-vw(30);
            }
        }
    }

    .swiper-slide {
        width: auto;
    }

    .card {
        background: $white;
        border-radius: 10px;
        padding: space(2);
        flex-basis: 45%;
        border: 0;
        max-width: 510px;

        @include responsive($tabletSmall) {
            max-width: get-vw(510);
        }

        p {
            font-size: 16px;
            line-height: 1.7;
            color: $text;
            margin-bottom: 24px;
    
            @include responsive($tabletSmall) {
                @include font-vw(16);
                margin-bottom: get-vw(24);
            }
        }

        .author {
            @include flex(center,initial);
            gap: 30px;

            .visual__container {
                position: relative;
                border-radius: 50%;
                width: 34px;
                @include imgRatio(1, 1, $grey-l8);
                overflow: hidden;

                @include responsive($tabletSmall) {
                    width: get-vw(34);
                }

                &::after {
                    display: none!important;
                }
            }

            span.name {
                font-weight: 500;
                font-size: 18px;
                line-height: 1.3;
                color: $base;
                display: block;
        
                @include responsive($tabletSmall) {
                    @include font-vw(18);
                }
            }
        
            span.position {
                font-size: 16px;
                line-height: 19px;
                color: $green-l1;
                display: block;
        
                @include responsive($tabletSmall) {
                    @include font-vw(16);
                }
            }
        }
    }
}