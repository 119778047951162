.Darkmode {
  &__toggle {
    position: relative;
    @include flex(center, center);
    margin-left: 6px;
    padding: 10px;
    background: 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    pointer-events: all;
    opacity: 0.3;
    transition: opacity 400ms $ease;

    @include responsive($tabletSmall) {
      margin-left: 30px;
    }

    @include noTouchHover() {
      opacity: 1;

      .Icon.is--sun {
        svg {
          transform: rotate(120deg);
        }
      }

      .Icon.is--moon {
        svg {
          transform: scale(1.2);
        }
      }
    }

    .Icon  {
      min-width: 18px;
      transition: transform 400ms $ease, opacity 400ms $ease;

      &.is--moon {
        position: absolute;
        opacity: 0;
        transform: scale(0) rotate(20deg);
      }

      .is--dark & {
        &.is--moon {
          opacity: 1;
          transform: scale(1) rotate(0deg);
        }

        &.is--sun {
          opacity: 0;
          transform: scale(0) rotate(-20deg);
        }
      }

      svg {
        transition: transform 400ms $ease;

        .--show-menu &, html.is--dark & {
          fill: var(--white);
        }
      }
    }
  }
}
