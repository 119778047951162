.Brand{
  body.--show-menu .Header &{
    pointer-events: none;

    @include responsive($mobile){
      opacity: 0.3;
    }

    svg > *{
      @include responsive($mobile, max){
        fill: var(--main-green);
        transition-delay: 750ms;
      }
    }
  }

  body.--show-submenu .Header &{
    @include responsive($mobile, max){
      opacity: 0;
    }
  }

  .Header &{
    transition: opacity 550ms var(--ease-in-out-quart);
    pointer-events: all;
    position: relative;
    z-index: 1;
  }

  svg > * {
    transition: fill 500ms var(--ease-in-out-quart);

    .Header &{
      @include responsive($mobile, max){
        transition-delay: 0ms;
      }
    }

    .Header:not(.--dark) &{
      fill: var(--white);
    }
    
    .Header.--dark &{
      fill: var(--main-green);
    }

    body.--scrolled .Header.--visible &{
      @include responsive($menuBreakpoint){
        fill: var(--main-green);
      }
    }
  }
}