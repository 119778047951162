.Close{
  @include flex(center, center);
  pointer-events: all;
  position: fixed;
  top: 0; right: 0;
  z-index: 10;
  height: get-vw(50);
  width: get-vw(50);
  min-height: 30px;
  min-width: 30px;
  border-radius: 5px;
  transform: translate(-1em, 1em);
  transition: transform 500ms var(--ease-in-out-quart);
  
  body.--show-article &{
    background: var(--green-l8);
  }

  body.--show-contact &{
    background: #013434;
  }

  @include noTouchHover() {
    transform: translate(-1em, 1em) scale(0.9);
  }

  &:before,
  &:after{
    @include content();
    position: absolute;
    height: 2px;
    width: 1em;
    background: var(--main-green);
  }

  &:before{
    transform: rotate(-45deg);
  }

  &:after{
    transform: rotate(45deg);
  }
}