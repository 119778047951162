.Page {
   &__header,
   &__template {
      padding-top: 240px;
      margin-bottom: 60px;

      @include responsive($tablet) {
         padding-top: 40vh;
         margin-bottom:10vh;
      }
   }

   &__content {
     padding-bottom: 60px;

     @include responsive($tablet) {
        padding-bottom: 20vh;
      }
   }

   &__template{
      .container{
         @include flex(flex-start);

         @include responsive($tabletSmall, max){
           flex-direction: column;
         }

         h1{
            width: 100%;

            @include responsive($tabletSmall, max){
              margin-bottom: 1.5em;
            }

            @include responsive($tabletSmall){
               position: sticky;
               top: 0.5em;
               max-width: space(15);
            }
         }

         .wswyg--content{
            @include responsive($tabletSmall){
              margin-left: space(5);
              flex: 1;
            }

            h2{
               margin-bottom: 24px;
            }
         }
      }
   }
}
