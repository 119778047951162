.PageHero{
  padding-top: 40vh;
  padding-bottom: 10vh;
  position: relative;
  // background: var(--black);
  
  &.--home{
    height: 100vh;
    overflow: hidden;

    .container{
      flex-direction: column;
    }

    h1{
      @include responsive($tabletSmall){
        @include font-vw(60);
      }

      @include responsive($deskXXL){
        @include font-vw(40);
      }
    }

    .Btn{
      align-self: start;
      margin-top: 2em;
    }

    .video__container{
      &:after{
        @include content();
        @include cover();
        background: linear-gradient(82.58deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.09) 64.67%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 66.38%);
      }
    }
  }

  &.--with-overlay{
    .visual__container{
      &:after{
        @include content();
        @include cover();
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &.--with-keywords{
    .container{
      h1{
        @include responsive($tabletSmall){
          max-width: get-vw(1000);
        }

        .--with-keywords{
          @include flex();

          @include responsive($tabletSmall, max){
            flex-direction: column;
          }
        }

        .kwds{
          flex: 1;

          @include responsive($tabletSmall){
            margin-left: 0.2em;
          }

          > span{
            &:not(:first-child){
              position: absolute;
              top: 0; left: 0;
              white-space: nowrap;
              opacity: 0;
            }
          }
        }
      }
    }
  }

  &:not(.--home){
    .container{
      .Btn{
        @include responsive($tabletSmall, max){
          margin-top: 2em;
          align-self: start;
        }

        @include responsive($tabletSmall){
          align-self: end;
          margin-left: auto;
        }
      }
    }
  }

  .container{
    @include flex();
    z-index: 1;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
    
    h1{
      width: 100%;

      @include responsive($tabletSmall){
        max-width: space(30);
      }
    }
  }

  .video__container > video{
    position: absolute;
    top: 0; left: 0;
    min-width: 100%;
    min-height: 100%;
  }

  .video__container{
    &:after{
      @include content();
      @include cover();
      background: linear-gradient(82.58deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.09) 64.67%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 66.38%);
    }

    .plyr__controls,
    .plyr__captions{
      display: none;
    }

    .plyr__video-wrapper,
    .plyr__video-wrapper > #player{
      @include cover();
    }
  }

  .visual__container,
  .video__container{
    @include cover();
    z-index: 0;
    // opacity: 0.75;
  }
}
