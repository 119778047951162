.SolutionCard{
  clip-path: inset(0% 0% 0% round 0.5em 0.5em 0.5em);
  display: block;
  position: relative;

  @include responsive($tabletSmall, max){
    height: 500px;
  }

  @include responsive($tabletSmall){
    height: get-vw(500);
  }

  @include noTouchHover() {
    .inner{
      h3,
      .more{
        transform: translate(0%, 0%);
      }

      .more{
        opacity: 1;
        transition-delay: 250ms;
      }

      .visual__container:not(.background){
        opacity: 1;
        transform: scale(1);
        transition-delay: 150ms;
      }

      .background{
        clip-path: inset(0% 0% 100% 0%);
      
        .visual{
          transform: scale(1.2);
        }
      }
    }
  }

  // For green info card (in Single Solution)
  &.--info{
    .inner{
      background: var(--main-green);

      > span{
        color: var(--green-l7);
        margin-bottom: 2em;

        @include responsive($tabletSmall){
          @include font-vw(11);
          margin-bottom: get-vw(20);
        }
      }

      p{
        font: 400 30px $nm;
        text-align: center;

        @include responsive($tabletSmall){
          @include font-vw(30);
        }

        @include responsive($deskXXL){
          @include font-vw(20);
        }
      }

      .fake-link{
        @include flex();
        font: 400 16px $nm;
        margin-top: 2em;

        .Icon[data-name="chevron"]{
          transform: translate(50%, 7%) rotate(-90deg);
          min-width: 13px;
          width: get-vw(13);

          svg > *{ stroke: var(--black) }
        }

        @include responsive($tabletSmall){
          @include font-vw(16);
          margin-top: get-vw(40);
        }
      }
    }
  }

  &:not(.--info){
    .inner{
      background: var(--green-l8);
    }
  }
  
  .inner{
    @include flex(center, center);
    flex-direction: column;
    z-index: 1;
    height: 100%;
    padding: 40px 20px 60px;

    > *:not(.background){
      position: relative;
      z-index: 1;
    }

    // Icon
    .visual__container:not(.background){
      @include imgRatio(150, 150);
      min-width: 130px;
      width: get-vw(100);
      margin-top: 2em;
      opacity: 0;
      transform: scale(0.8);
      transition: opacity 750ms var(--ease-in-out-quart) 0ms,
                  transform 750ms var(--ease-in-out-quart) 0ms;


      @include responsive($tabletSmall){
        margin-top: get-vw(40);  
        min-width: 70px;
      }

      @include responsive($tablet){
        width: get-vw(150);
      }
    }

    // Title + link title
    h3{
      margin-top: auto;
      text-align: center;
      color: var(--white);
      mix-blend-mode: difference;
      transform: translate(0, 40%);
      transition: color 750ms var(--ease-in-out-quart),
                  transform 750ms var(--ease-in-out-quart);

      @include responsive($tabletSmall){
        @include font-vw(30);
      }

      @include responsive($deskXXL){
        @include font-vw(20);
      }
    }

    .more{
      @include flex(center);
      margin-top: 2em;
      font: 400 16px $nm;
      opacity: 0;
      transform: translate(0, 100%);
      transition: opacity 500ms var(--ease-in-out-quart) 0ms,
                  transform 500ms var(--ease-in-out-quart) 0ms;

      @include responsive($tabletSmall){
        @include font-vw(16);
      }

      .Icon{
        transform: translate(20%, 10%) rotate(-90deg);
        width: auto;
        min-width: 15px;
      }
    }

    .background{
      clip-path: inset(0% 0% 0% 0%);
      transition: clip-path 750ms var(--ease-in-out-quart) 100ms;

      .visual{
        transition: transform 750ms var(--ease-in-out-quart) 100ms;
      }
    
      &, 
      &:after{
        @include cover();
      }

      &:after{
        @include content();
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      }
    }
  }
}
