.ComparisonTitle{
  @include flex(center);

  > span{
    font: 700 15px $nm;
    text-transform: uppercase;
    margin-left: 0.5em;

    @include responsive($tabletSmall){
      @include font-vw(15);
    }
  }
}