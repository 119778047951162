.Nav__toggle {
  all: unset;
  @include flex(center, center);
  padding: 10px;
  z-index: 10;
  pointer-events: all;
  cursor: pointer;

  @include responsive($menuBreakpoint) {
    display: none;
  }

  // body.--show-menu .Header &{
  //   .line{
  //     transition-delay: 500ms;

  //     &:first-child{
  //       transform: scaleX(0);
  //     }
      
  //     &:nth-child(2){
  //       transform: translate(0%, 0.4em) rotate(45deg);
  //     }

  //     &:nth-child(3){
  //       transform: translate(0%, -0.4em) rotate(-45deg)
  //     }
  //   }
  // }

  body.--show-submenu .Header &{
    opacity: 0;
  }

  .icon{
    @include flex(center, center);
    height: get-vw(20);
    width: get-vw(20);
    position: relative;

    @include responsive($mobile, max){
      min-height: 20px;
      min-width: 20px;
    }
  }

  .line {
    position: absolute;
    top: 50%; left: 50%;
    height: 2px;
    width: 100%;
    transition: background 400ms $easeOutQuad 0ms,
                transform 400ms $easeOutQuad 0ms;


    &:first-child{
      transform: translate(-50%, -0.35em);

      body.--show-menu &{
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
    
    &:nth-child(2){
      transform: translate(-50%, 0);

      body.--show-menu &{
        transform: translate(-50%, 0) scaleX(0);
      }
    }
    
    &:nth-child(3){
      transform: translate(-50%, 0.35em);

      body.--show-menu &{
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .Header:not(.--dark) &{
      background: var(--white);

      body.--show-menu &{
        background: var(--black);
      }
    }
    
    .Header.--dark &{
      background: var(--black);
    }
  }
}
