.Team{
  > .container{
    @include flex();

    @include responsive($tabletSmall, max){
      align-items: flex-start;
      flex-direction: column;
    }

    @include responsive($tabletSmall) {
      align-items: center;
      padding: 0 calc(100vw / 24);
    }
  }

  .wrapper{
    width: 100%;

    @include responsive($tabletSmall, max){
      margin-bottom: 2.5em;
    }

    @include responsive($tabletSmall){
      max-width: space(25);
    }

    h2{
      line-height: 1.2;

      @include responsive($tabletSmall, max){
        font-size: 7vw;
      }

      > .--with-keywords{
        @include flex();
      }

      .kwds{
        margin-left: 0.2em;
        flex: 1;

        > span{
          &:not(:first-child){
            position: absolute;
            top: 0; left: 0;
            white-space: nowrap;
            opacity: 0;
          }
        }
      }
    }

    .Btn{
      margin-top: 2em;

      @include responsive($tabletSmall){
        margin-top: get-vw(50);
      }
    }
  }

  .visual__container{
    @include imgRatio(510, 600, var(--grey-l));
    clip-path: inset(0% 0% 0% round 0.5em 0.5em 0.5em);

    @include responsive($tabletSmall, max){
      max-width: 80%;
      margin-left: auto;
    }

    @include responsive($tabletSmall){
      max-width: space(17);
      margin-left: auto;
    }
  }
}