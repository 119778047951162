#XHR{
  position: fixed;
  top: 0; right: 0;
  height: 100%;
  width: 100%;
  z-index: 121;
  overflow-y: scroll;
  
  body.--show-article &{
    @include responsive($tabletSmall){
      max-width: 70%;
    }
  }

  body.--show-contact &{
    // background: 
  }
}