.CheckboxWithIcon{
  @include flex(center);
  flex-direction: column;
  padding: 40px 10px;
  position: relative;
  text-align: center;

  &.--liberal{
    .visual__container{
      background: #002323;
      clip-path: inset(0px 0px 0px round 7px 7px 7px);
    }
  }

  > *:not(input){
    pointer-events: none;
  }

  > .visual__container{
    @include imgRatio(70, 70);
    width: get-vw(70);
    min-width: 50px;
  }

  .label{
    margin: 0.8em 0;
  }

  input{
    @include cover();
    // opacity: 0;
    cursor: pointer;
    border-radius: var(--radius);
    border: 2px solid rgba(255, 255, 255, 0.12);
    transition: border 350ms var(--ease-out-quart);

    &:checked{
      border-color: var(--main-green);
    }
  }
}