.Block__news {
    overflow: hidden;

    .headline {
        @include flex(center,space-between);
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 24px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(24);
        }

        h2, .swiper-navigation {
            margin: 0!important;
        }

        .swiper-navigation > * {
            background: $green-l7;
    
            svg > * {
                stroke: $green-l1!important;
            }
        }
    }

    .swiper:not(.swiper-initialized) {
        .swiper-wrapper {
            gap: 30px;

            @include responsive($tabletSmall) {
                gap: get-vw(30);
            }
        }
    }

    .ArticlePreview {
        text-decoration: none!important;
        background: $white;

        .visual__container {
            &::after {
                display: none!important;
            }

            &:before {
                background: $base;
            }
        }

        p {
            color: $text-green;
            font-weight: 300;
            font-size: 19px;

            @include responsive($tabletSmall) {
                @include font-vw(19);
            }
        }

        .Btn {
            margin-top: 20px;
            
            @include responsive($tabletSmall) {
                margin-top: get-vw(20);
            }
        }
    }
}