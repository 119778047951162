.Modal {
  &__panel {
    @include cover(fixed);
    z-index: 1000;

    .Close {
      position: absolute;
      top: 40px;
      right: 30px;
      background: 0;
      border: 0;
      padding: 0;
      @include flex(center, center);
      width: 40px;
      height: 40px;
      z-index: 10;
      cursor: pointer;
      transition: transform 350ms $easeInOutQuad;

      @include responsive($tabletSmall) {
        top: 6vw;
        right: 6vw;
      }

      @include noTouchHover(){
        transform: scale(0.75) rotate(-180deg)
      }

      svg > * {
        stroke: $base;
        stroke-width: 3px;
      }
    }
  }

  &__wrapper {
    @include cover();
    overflow: auto;
    background: var(--white);
  }
}
