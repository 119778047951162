h1, h2, h3, h4, h5, h6{
	strong{
		font-weight: 400;
	}
}

h1, h2{
	&.--mid{
		font: 400 40px $nm;

		@include responsive($tabletSmall){
			@include font-vw(40);
		}

		@include responsive($deskXXL){
			@include font-vw(30);
		}
	}
}

h1 {
	font: 400 8vw $nm;
	color: var(--black);
	line-height: 1.2;

	@include responsive($tabletSmall){
		@include font-vw(80);
	}

	@include responsive($deskXXL){
		@include font-vw(50);
	}
}

h2 {
	font: 400 35px $nm;
	line-height: 1;

	@include responsive($tabletSmall) {
		@include font-vw(60);
	}

	@include responsive($deskXXL){
		@include font-vw(40);
	}

	&.--big{
		font: 400 60px $nm;

		@include responsive($tabletSmall){
			@include font-vw(100);
		}

		@include responsive($deskXXL){
			@include font-vw(60);
		}
	}
}

h3 {
	font: 400 get-vw(80) $nm;

	@include responsive($tabletSmall){
		@include font-vw(40);
	}

	@include responsive($deskXXL){
		@include font-vw(30);
	}
}

h4 {
	font-size: 1.6rem;
	margin-bottom: 20px;
}

.--title{
	font: 400 25px $nm;
	// color: $white;

	@include responsive($tabletSmall){
		@include font-vw(40);
	}

	@include responsive($deskXXL){
		@include font-vw(30);
	}
}

.subtitle {
	&.--garaje {
		text-transform: uppercase;
		letter-spacing: -0.025em;
	}

	&:not(.--little){
		font: 700 24px $garaje;

		@include responsive($tabletSmall) {
			@include font-vw(24);
		}

		@include responsive($deskXXL){
			@include font-vw(20);
		}
	}

	&.--little{
		font: 700 18px $garaje;
		// color: var(--main-green);

		@include responsive($tabletSmall){
			@include font-vw(18);
		}
	}

	&.--tiny{
		font: 700 11px $nm;
		text-transform: uppercase;
		letter-spacing: 0.1em;

		@include responsive($tabletSmall){
			@include font-vw(11);
		}
	}
}

.tag{
	display: inline-flex;
	padding: 0.8em 1.75em;
	background: var(--green-l8);
	border-radius: 20em;
	font: 700 12px $nm;
	text-transform: uppercase;
	color: var(--main-green);

	@include responsive($tabletSmall){
		@include font-vw(12);
	}
}

strong {
	font-weight: bold;
	font-weight: 700;
}

p {
	line-height: 1.6;

	&.no__results {
		text-align: center;
		font-size: 3.2rem;
		font-weight: bold;
		opacity: 0.2;
		margin-top: 30px;
	}

	&.--20 {
		@include responsive($tabletSmall) {
			@include font-vw(20);
		}

		@include responsive($deskXXL){
			@include font-vw(18);
		}
	}
}

.wswyg--content {
	text-align: left;

	> * + * {
		margin-top: 1em;
	}

	* + h2 {
		margin-top: 40px;
	}

	h2 {
		font: 400 28px $nm;
		margin-bottom: 1em;

		@include responsive($tabletSmall){
			@include font-vw(28);
		}
	}

	h3{
		font: 400 24px $nm;

		@include responsive($tabletSmall){
			@include font-vw(24);
		}
	}

	h4{
		font: 400 22px $nm;

		@include responsive($tabletSmall){
			@include font-vw(22);
		}
	}

	h5{
		font: 400 20px $nm;

		@include responsive($tabletSmall){
			@include font-vw(20)
		}
	}

	h6{
		font: 400 18px $nm;

		@include responsive($tabletSmall){
			@include font-vw(18);
		}
	}

	hr{
		border: 1px solid var(--accordion-border);
	}

	*{
		strong{
			font-weight: 500;
		}

		&.alignleft{
			margin-left: 0;
		}

		&.alignright{
			margin-left: auto;
		}

		&.aligncenter{
			margin-left: auto;
			margin-right: auto;
		}
	}

	ul,
	ol {
		@extend p;
		margin-left: 1em;
		margin-top: 1em;
		line-height: 1.6;
		list-style-type: disc;

		> li + li {
			margin-top: 0.5em;
		}

		code {
			margin: 1em 0;
		}
	}

	ol {
		list-style-type: decimal;
	}

	p {
		@include responsive($tabletSmall){
			@include font-vw(20);
		}
	}

	strong{
		color: inherit;
	}

	figure {
		margin: 40px auto;
		overflow: hidden;

		&:last-child {
			margin-bottom: 0px;
		}

		img {
			display: block;
		}
	}

	a {
		color: $links-color;
		font-weight: bold;
		text-decoration: underline;
	}

	em{
		font-style: italic;
	}

	blockquote{
		font-style: italic;
		border-left: 2px solid var(--text);
		padding-left: 1.5em;
	}
}
