.Overlay {
  @include cover(fixed);
  display: block;
  padding: 0;
  margin: 0;
  background: $base;
  opacity: 0;
  pointer-events: none;
  z-index: 80;
  cursor: pointer;
  outline: 0;
  box-shadow: none;
  border: 0;
  visibility: hidden;
  transition: all 550ms var(--ease-in-out-quart) 0ms;

  body.--show-menu &,
  body.--show-article &,
  body.--show-contact &{
    visibility: visible;
    opacity: 0.8;
    pointer-events: all;
  }

  body.--show-submenu &{
    pointer-events: none;
  }
}
