.Block__socials {
    background: $green-d8;
    text-align: center;
    border-radius: 10px;
    padding: 64px 20px;

    @include responsive($tabletSmall) {
        padding: get-vw(64) space(1);
    }

    .inner {
        margin: auto;

        @include responsive($tabletSmall) {
            max-width: 55%;
        }

        h3 {
            color: $white!important;
            margin-bottom: 32px;
            margin-top: 0!important;

            @include responsive($tabletSmall) {
                margin-bottom: get-vw(32);
            }

            strong {
                color: var(--green-l1)!important;
            }
        }

        ul {
            @include flex(center,center);
            gap: 16px;
            list-style-type: none;
            padding:0;
            margin:0;

            @include responsive($tabletSmall) {
                gap: get-vw(16);
            }

            li {
                margin: 0;
            }

            a {
                @include flex(center, center);
                background: var(--green-l7);
                width: 48px;
                height: 48px;
                border-radius: 50%;

                @include responsive($tabletSmall) {
                    width: get-vw(48);
                    height: get-vw(48);
                }
            }

            .Icon {
                @include imgRatio(1, 1);
                min-width: 25%;

                svg {
                    > * {
                        fill: var(--green-d1);
                    }
                }
            }
        }
    }
}