.AboutPresentation{
  .horizontal-scroll{
    display: flex;
    
    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
    
    @include responsive($tabletSmall){
      height: 100vh;
      flex-wrap: nowrap;
    }
  }

  .panel{
    @include responsive($tabletSmall){
      @include flex(center);
      height: 100%;
      flex-wrap: nowrap;
    }

    & + .panel{
      @include responsive($tabletSmall, max){
        margin-top: 5em;
      }

      @include responsive($tabletSmall){
        padding-left: 20em;
        padding-right: 30em;
      }
    }

    h2{
      z-index: 0;

      @include responsive($tabletSmall, max){
        font-size: 32px;
        margin-bottom: 1em;
      }

      @include responsive($tabletSmall){
        min-width: space(25);
      }
    }

    .cards{
      @include flex();
      position: relative;
      z-index: 1;
      gap: 1em;

      @include responsive($tabletSmall, max){
        padding-bottom: 1em;
      }
      
      @include responsive($tabletSmall){
        margin-left: 30em;
      }
    }

    .swiper{
      @include responsive($tabletSmall){
        margin-left: 10em;
      }

      &-navigation{
        @include responsive($tabletSmall, max){
          margin-bottom: 2em;
        }

        @include responsive($tabletSmall){
          display: none;
        }
      }

      &-wrapper{
        @include responsive($tabletSmall){
          cursor: initial;
        }
      }

      &-slide{
        height: get-vw(400);
        width: get-vw(500);
        min-height: 300px;
        min-width: 300px;

        & + .swiper-slide{
          @include responsive($tabletSmall){
            margin-left: 1em;
          }
        }
      }
    }
  }
}