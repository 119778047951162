.JoinTheClub{
  text-align: center;
  
  &:not(.--clipping){
    background: var(--white);
  }

  &.--clipping{
    background: var(--green-l7);
  }

  .IconsMarquee{
    @include responsive($tabletSmall, max){
      margin-bottom: 4em;
    }

    @include responsive($tabletSmall){
      margin-bottom: get-vw(100);
    }
  }

  .subtitle{
    color: $green-l1;
    margin-bottom: 20px;

    @include responsive($tabletSmall){
      margin-bottom: get-vw(20);
    }
  }

  h2{
    margin-bottom: 30px;

    @include responsive($tabletSmall){
      margin-bottom: get-vw(30);
    }
  }

  p{
    max-width: 350px;
    margin: 0 auto;

    @include responsive($tabletSmall){
      max-width: get-vw(450);
    }
  }

  .Btn{
    margin-top: 50px;

    @include responsive($tabletSmall){
      margin-top: get-vw(50);
    }
  }
}