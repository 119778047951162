.Block__textImage {
    width: 100%;
    
    @include responsive($tabletSmall) {
        @include flex(stretch, space-between);
    }

    > * {
        width: 100%;
    }

    .visual__container {
        position: relative;
        border-radius: 10px;
        min-height: 240px;
        overflow: hidden;

        @include responsive($tabletSmall) {
            max-width: 40%;
            border-radius: get-vw(10);
        }

        &::after {
            display:none!important;
        }
    }

    .text {
        padding: 40px 0 0;

        @include responsive($tabletSmall) {
            padding: get-vw(60) 0;
            max-width: 50%;
        }

        h3 {
            font-size: 38px;
            line-height: 1.2!important;
            margin-bottom: 25px;
 
            @include responsive($tabletSmall) {
                @include font-vw(38);
                margin-bottom: get-vw(25);
            }
        }

        p {
            color: $text-green;
            font-size: 20px;
            line-height: 1.6!important;
 
            @include responsive($tabletSmall) {
                @include font-vw(20);
            } 
        }
    }

    &.--reverse {
        flex-direction: row-reverse;
    }
}