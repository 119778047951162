.Nav {
  &__container {
    pointer-events: all;
    flex: 1;
    overscroll-behavior: contain;

    .--animating & {
      pointer-events: none;
    }
    
    @include responsive($menuBreakpoint, max){
      @include flex(flex-start);
      position: fixed;
      top: 0; right: 0;
      height: 100%; width: 100%;
      overflow-y: scroll;
      flex-direction: column;
      background: var(--grey-l8);
      padding-top: 15em;
      padding-bottom: 5em;
      max-width: get-vw(750);
      clip-path: inset(0% 0% 0% 100%)
    }

    @include responsive($mobile, max){
      max-width: none;
    }

    > .container{
      @include flex($align: center);
      position: initial;
      
      @include responsive($menuBreakpoint, max){
        flex-direction: column;
      }

      @include responsive($tabletSmall){
        padding: 0 4vw;
      }
      
      @include responsive($menuBreakpoint){
        padding: 0;
      }

      > *{
        @include responsive($menuBreakpoint, max){
          width: 100%;
        }
      }
    }
  }

  &__main,
  &__buttons > ul{
    display: flex;
  }

  &__main {
    @include responsive($menuBreakpoint, max){
      grid-gap: 1.2em;
      flex-direction: column;
    }

    @include responsive($menuBreakpoint){
      gap: get-vw(24);
      margin-left: get-vw(50);
      align-items: center;
    }

    .line{
      height: 1px;
      width: 100%;
      background: rgba(10, 10, 10, 0.2);

      @include responsive($menuBreakpoint){
        display: none;
      }
    }
  }

  &__buttons{
    @include responsive($menuBreakpoint, max){
      margin-top: 2em;
    }
    
    @include responsive($menuBreakpoint){
      margin-left: auto;
    }

    > ul{
      gap: get-vw(14);
    }

    .Btn.--space{
      background: var(--black);
      color: var(--white);
      transition: color 500ms var(--ease-in-out-quart),
                  background 500ms var(--ease-in-out-quart);

      @include responsive($menuBreakpoint){
        color: var(--white);
        background: rgba(0, 0, 0, 0.04);
      }

      Header.--dark &{
        @include responsive($menuBreakpoint){
          background: var(--black);
          color: var(--white);
        }
      }
    
      body.--scrolled .Header.--visible &{
        @include responsive($menuBreakpoint){
          color: var(--text);
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }

  &__item {
    @include flex();

    &:not([data-slug="nos-solutions"]){
      position: relative;
      z-index: 1;
    }

    &[data-slug="nos-solutions"]{
      &, > .solutions-container{
        @include flex();
      }

      > .solutions-container{
        position: relative;
        z-index: 1;
      }

      .Link{
        @include responsive($menuBreakpoint, max){
          flex: 1;
          margin-right: 30%;
        }
      }

      .Icon[data-name="triangle"]{
        position: absolute;
        top: 100%; left: 50%;
        transform: translate(-50%, 150%);

        @include responsive($menuBreakpoint, max){
          display: none;
        }
      }

      .submenu-btn{
        @include flex();

        @include responsive($menuBreakpoint){
          margin-top: 0.15em;
          margin-left: 0.25em;
        }

        &.--only-mobile{
          @include responsive($tablet){
            display: none;
          }
        }

        &.--only-desktop{
          @include responsive($tablet, max){
            display: none;
          }
        }

        &.--btn{
          width: 100%;
          align-items: center;

          > span{
            font: 500 20px $nm;
            transition: color 500ms var(--ease-in-out-quart);
            flex: 1;

            @include responsive($tabletSmall){
              @include font-vw(18);
            }
          }
        }

        .Icon[data-name="chevron"]{
          width: get-vw(10);

          @include responsive($tablet){
            margin-left: 0.25em;
          }

          svg {
            @include responsive($menuBreakpoint, max){
              transform: rotate(-90deg);
            }

            > * {
              @include responsive($menuBreakpoint, max){
                stroke: var(--black);
              }

              .Header:not(.--dark) &{
                @include responsive($menuBreakpoint){
                  stroke: var(--white);
                }
              }
          
              .Header.--dark &,
              body.--scrolled .Header.--visible &{
                @include responsive($menuBreakpoint){
                  stroke: var(--black);
                }
              }
            }
          }
        }
      }
    }

    > *{
      @include responsive($menuBreakpoint, max){
        width: 100%;
      }
    }
  }

  &__link {
    font: 500 20px $nm;
    transition: color 500ms var(--ease-in-out-quart);

    @include responsive($tabletSmall){
      @include font-vw(18);
    }

    @include responsive($deskXXL){
      @include font-vw(15);
    }

    .Header:not(.--dark) &{
      body:not(.--show-menu) &{
        color: var(--white);
      }

      &:before{ background: var(--white) }
    }
    
    .Header.--dark &{
      body:not(.--show-menu){
        color: var(--black);
      }

      &:before{ background: var(--black) }
    }

    body.--show-menu &{
      color: var(--black);

      &:before{ background: var(--black) }
    }

    body.--scrolled .Header.--visible &{
      @include responsive($menuBreakpoint){
        color: var(--black);

        &:before{ background: var(--black) }
      }
    }
  }
}
