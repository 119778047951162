.Loader {
  @include cover(fixed);
  z-index: 100;
  background: var(--white);
  visibility: hidden;

  html.is--dark & {
    background: darken($base, 5%);
  }

  body.--intro & {
    visibility:hidden!important;
  }

  body.--animating & {
    visibility:visible;
  }
}
