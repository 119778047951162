.ContactForm {
  .Form{
    @include responsive($tabletSmall){
      max-width: space(35);
      margin: 0 auto;
    }

    .row > .grid{
      display: grid;

      @include responsive($tabletSmall, max){
        grid-gap: 1.5em;
        grid-template-areas: 
          "lastname"
          "firstname"
          "email"
          "tel"
          "city"
          "methods"
          "hours"
          "message"
        ;  
      }
      
      @include responsive($tabletSmall){
        grid-gap: get-vw(30);
        grid-template-areas: 
          'lastname methods'
          'firstname hours'
          'email message'
          'tel message'
          'city message'
        ;
      }
    }

    &__group{
      &.--lastname{
        grid-area: lastname;
      }

      &.--firstname{
        grid-area: firstname;
      }

      &.--email{
        grid-area: email;
      }

      &.--tel{
        grid-area: tel;
      }

      &.--city{
        grid-area: city;
      }

      &.--methods{
        grid-area: methods;
      }

      &.--hours{
        grid-area: hours;
      }

      &.--message{
        grid-area: message;
      }

      // Select styles
      &.is--select{
        select.Form__control{
          color: var(--white);

          option{
            color: initial;
          }
        }
      }
    }

    .checkboxes{
      margin-top: 4em;

      @include responsive($tabletSmall){
        margin-top: get-vw(70);
      }

      .head{
        margin-bottom: 2em;

        h2{
          font-size: 20px;
          margin-bottom: 0.5em;

          @include responsive($tabletSmall){
            @include font-vw(30);
          }

          @include responsive($deskXXL){
            @include font-vw(20);
          }
        }

        p{
          color: var(--text-green);
        }
      }

      > .grid{
        display: grid;
        grid-gap: 1em;

        @include responsive($tabletSmall, max){
          grid-template-columns: repeat(2, 1fr);  
        }

        @include responsive($tabletSmall){
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }

    .bottom{
      @include flex();
      margin-top: 4em;
      gap: 4em;

      @include responsive($tabletSmall, max){
        flex-direction: column;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(70);
      }

      .Checkbox{
        &__label{
          font: 400 16px $nm;
          margin-left: 1em;
          color: var(--white);
          filter: opacity(0.6);
          flex: 1;

          @include responsive($tabletSmall){
            @include font-vw(16);
          }
        }
      }
    }

    .Form__output{
      @include cover(fixed);
      @include flex(center, center);
      z-index: 1000;
      background: rgba(0, 0, 0, 0.65);
      transition: opacity 250ms $ease;

      &:not(.--active){
        opacity: 0;
        pointer-events: none;
      }

      &.--active{
        p:not(:empty) {
          &:before{
            transform: scaleX(1);
          }
        }
      }

      p:not(:empty){
        position: relative;
        margin: 0;
        padding: 2em;
        background: var(--green-d2);
        color: $white;
        width: 100%;
        text-align: center;
        max-width: 80%;
        
        @include responsive($mobile){
          max-width: 350px;
        }
        
        @include responsive($tabletSmall){
          max-width: get-vw(450);          
        }

        &:before{
          @include content();
          position: absolute;
          top: 0; left: 0;
          width: 100%; height: 5px;
          background: var(--green-d8-l);
          transform: scaleX(0);
          transform-origin: 0% 0%;
          // transition: transform 2500ms linear 250ms;
          transition: transform 7500ms linear 250ms;
        }
      }
    }
  }
}
