.Block__questionnaire {
    background: $white;
    border-radius: 10px;
    padding: 30px;
    padding-bottom: 0!important;

    @include responsive($tabletSmall) {
        padding: space(2);
        border-radius: get-vw(10);
    }

    > .wrapper {
        @include responsive($tabletSmall) {
            @include flex(initial,space-between);
            gap: space(2);
        }
    }

    .questions {
        flex-basis: 53%;
    
        @include responsive($tabletSmall) {
            order: 1;
        }

        h3 {
            margin-bottom: 40px;

            @include responsive($tabletSmall) {
                margin-bottom: get-vw(40);
            } 
        }

        .question {
            margin-bottom: 32px;

            @include responsive($tabletSmall) {
                margin-bottom: get-vw(32);
            }

            p {
                margin-bottom: 20px;
                color: $text;
    
                @include responsive($tabletSmall) {
                    margin-bottom: get-vw(20);
                }
            }

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    position: relative;

                    input {
                        opacity: 0;
                        @include cover();
                        cursor: pointer;

                        &:checked + .Checkbox__wrapper {
                            background-color: $green-l8!important;
                            border-color: darken($green-l8, 5%);

                            .Checkbox__custom {
                                border: none;
                                background: $green-l7;
                                svg {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    @include noTouchHover() {
                        .Checkbox__wrapper {
                            background: $grey-l8;
                        }
                    }
                }
            }

            .Checkbox__wrapper {
                border: 1px solid $grey-l6;
                border-radius: 10px;
                margin-bottom: 12px;
                padding: 13px 16px;
                color: $text;
                line-height: 1.7;
                font-size: 17px;
                display: flex;
                gap: 10px;
                transition: background-color 0.2s ease-in-out;
    
                @include responsive($tabletSmall) {
                    margin-bottom: get-vw(12);
                    padding: get-vw(13) get-vw(16);
                    @include font-vw(17);
                    gap: get-vw(10);
                }

                .Checkbox__custom {
                    @include flex(center, center);
                    width: 24px;
                    height: 24px;
                    border: 1px solid $grey-l5;
                    border-radius: 50%;
                    transition: background-color 0.2s ease-in-out;

                    @include responsive($tabletSmall) {
                        width: get-vw(30);
                        height: get-vw(30);
                    }

                    svg {
                        opacity: 0;
                        transition: opacity 0.2s ease-in-out;
                        fill: transparent;
                        width: 50%;
                        overflow: visible;

                        > * {
                            stroke: $green-d1;
                            stroke-width: 5px;
                        }
                    }
                }
            }
        }
    }

    .results {
        flex-basis: 37%;
        background: $green-d8;
        padding: 40px;
        height: max-content;
        border-radius: 10px;
        color: $text-green;
        margin: 0 -20px;
        border-radius: 0 0 10px 10px;

        @include responsive($tabletSmall) {
            margin: 0 space(-2);
            padding: space(2);
            border-radius: 0 0 get-vw(10) get-vw(10);
        }

        h5 {
            margin: 30px 0 10px!important;
            color: $white;
            font-size: 17px;

            @include responsive($tabletSmall) {
                @include font-vw(17);
                margin: get-vw(30) 0 get-vw(10)!important;
            }

            strong {
                color: $green-d1;
            }

            &:first-child {
                margin-top: 0!important;
            }
        }

        p {
            font-size: 15px;
            color: $white;

            @include responsive($tabletSmall) {
                @include font-vw(15);
            }
        }
    }

    .visual__wrapper {
        flex-basis: 37%;
        padding-bottom: 40px;
        margin-top: -60px;
    
        @include responsive($tabletSmall) {
            order: 2;
            margin-top: 0;
        }

        .visual__container {
            position: sticky;
            top: 40px;
            @include imgRatio(330, 330, $green-d8);
            max-height: calc(100vh - 80px);

            @include responsive($tabletSmall) {
                @include imgRatio(330, 640, $green-d8);
            }
        }
    }
}