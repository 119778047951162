.NotFound{
  .PageHeader{
    .container{
      @include flex(center, center);
      flex-direction: column;
    }

    h1{
      font: 400 150px $nm;

      @include responsive($tabletSmall){
        @include font-vw(150);
      }

      @include responsive($deskXXL){
        @include font-vw(100);
      }
    }

    p{
      text-align: center;
      max-width: 15em;
      margin: 0 auto;
    }

    .Btn{
      align-self: center;
      margin: 2em 0;
    }
  }

}