.SolutionRelatedArticles{
  .wrapper{
    @include responsive($tabletSmall){
      margin: 0 space(3);
    }
  }

  h2{
    text-align: center;

    @include responsive($tabletSmall, max){
      margin-bottom: 1em;
    }

    @include responsive($tabletSmall){
      max-width: space(12);
      margin: 0 auto get-vw(80);
    }
  }
}