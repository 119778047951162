.visual {
  @include cover();
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-size: cover;
  // transform: scale(1.02);
  // transform-origin: center;
  // will-change: transform;

  video {
    @include cover();
    object-fit: cover;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  &[data-bg] {
    @include cover();
    background-size: cover;
    background-position: center;
  }

  &__container {
  	width: 100%;

  	&:before {
  		@include content();
  	}
  }
}
