.CheckboxContainer{
  &.--show-all-label{
    > button{
      span{
        &:first-child{
          transform: translate(0, -100%);
          opacity: 0;
        }

        &:nth-child(2){
          transform: translate(0, 0);
          opacity: 1;
        }
      }
    }

    .Checkbox__label{
      height: auto;

      .Newsletter &{
        height: auto;
      }

      &:after{
        display: none;
      }
    }
  }

  > button{
    font: 500 12px var(--main-font);
    color: var(--main-green);
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    width: 5em;
    margin-top: 1em;

    @include responsive($tabletSmall, max){
      margin-left: 60px;
    }

    @include responsive($tabletSmall){
      @include font-vw(12);
      margin-left: get-vw(60);
    }

    span{
      text-decoration: underline;
      transition: transform 300ms var(--ease-out-quart);

      &:nth-child(2){
        position: absolute;
        top: 0; left: 0;
        transform: translate(0, 100%);
      }
    }
  }

  .Checkbox__label{
    height: 70px;
    overflow: hidden;

    .Newsletter &{
      height: 55px;
    }

    &:after{
      @include content();
      position: absolute;
      bottom: 0; left: 0;
      height: 3.5em;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 43, 43, 0) 0%, rgba(0, 43, 43, 0.78) 38.78%, var(--green-d8) 100%);

      .Newsletter.--light &{ background: linear-gradient(180deg, rgba(208, 245, 239, 0) 0%, rgba(208, 245, 239, 0.78) 38.78%, var(--green-l7) 100%); }
    }
  }
}

.Checkbox {
  @include flex(flex-start, flex-start, $wrap: nowrap);
  position: relative;

  &.--inline{
    input{
      @include cover();
      opacity: 0;
      z-index: 10;
      cursor: pointer;
    }

    .Checkbox__label{
      font: 400 13px $nm;
      line-height: 1.45;
      color: $text-green;
      flex: 1;
      padding-left: 1em;

      @include responsive($tabletSmall){
        @include font-vw(13);
      }
    }
  }

  &__mark{
    position: relative;
    width: get-vw(45);
    height: get-vw(25);
    border-radius: 20em;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.1);
    transition: outline-color 200ms $SineEaseInOut 50ms, 
                background 200ms $SineEaseInOut 50ms;
    
    @include responsive($tabletSmall, max){
      min-width: 45px;
      min-height: 25px;
    }

    @include responsive($tabletSmall){
      min-width: 26px;
      min-height: 10px;
    }

    input:checked ~ & {
      background: $green-l1;
      border-color: #37CCB4;

      &:after{
        transform: translate(100%, -50%);
      }
    }
    
    &:after{
      @include content();
      position: absolute;
      top: 50%; left: 0;
      width: get-vw(20);
      height: get-vw(20);
      border-radius: 50%;
      background: var(--white);
      transform: translate(5%, -50%);
      transition: transform 200ms $SineEaseInOut;

      @include responsive($tabletSmall, max){
        min-height: 20px;
        min-width: 20px;
      }

      @include responsive($tabletSmall){
        min-height: 8px;
        min-width: 8px;
      }
    }
  }
}

