.Newsletter{
  body.--show-contact &{
    display: none;
  }

  &:not(.--light){
    background: var(--green-d8);

    .inner{
      .head{
        h2{ color: var(--main-green) }
        p{ 
          color: var(--white); 
          filter: opacity(0.4);
        }
      }

      .Form{
        &__control{ background: rgba(255, 255, 255, 0.1) }
      }
    }
  }

  &.--light{
    background: var(--green-l7);

    .inner{
      .head{
        h2{ color: var(--green-d2) }
        p{ color: var(--text-green) }
      }

      .Form{
        &__control{ 
          background: rgba(0, 120, 104, 0.1);
          color: var(--green-d8);

          &::placeholder{ color: var(--text-green) }
        }

        &__actions{
          .Btn.--secondary{
            background: var(--main-green);
            color: var(--white);
          }
        }

        .Checkbox{
          input:not(:checked) ~ .Checkbox__mark{ 
            background-color: #bee8e2;
            border: none;
          }

          &__label{
            a{
              color: var(--green-d8-l);
            }
          }
        }
      }
    }
  }
  
  .inner{
    @include flex();
    width: 100%;
    padding: 0 space(4);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    .head{
      width: 100%;

      @include responsive($tabletSmall, max){
        margin-bottom: 30px;
      }
    
      @include responsive($tabletSmall){
        max-width: get-vw(400);
      }

      h2{
        font: 400 40px $nm;
        margin-bottom: 0.5em;

        @include responsive($tabletSmall){
          @include font-vw(40);
        }

        @include responsive($deskXXL){
          @include font-vw(30);
        }
      }

      p{
        font: 400 17px $nm;

        @include responsive($tabletSmall){
          @include font-vw(17);
        }
      }
    }

    .Form{
      margin-left: auto;

      @include responsive($tabletSmall){
        padding-top: 1em;
        max-width: space(15);
      }

      .head{
        position: relative;
        margin-bottom: 1em;
      }

      &__group{
        &.is--email{
          span.error{
            top: auto;
            bottom: 110%;
          }
        }
      }

      &__control{
        border: none;
        padding-right: 30%;
      }

      &__actions{
        position: absolute;
        top: 50%; right: 0;
        transform: translate(-5%, -50%);
      }

      &__output{
        p{
          font-size: 0.75em;
        }
      }
    }
  }
}