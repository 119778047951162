.SolutionsSubmenu{
  @include responsive($menuBreakpoint, max){
    @include cover(fixed);
    left: auto; right: 0;
    max-width: get-vw(750);
    z-index: 100;
    overflow-y: scroll;
  }

  @include responsive($mobile, max){
    max-width: none;
    left: 0; right: auto;
  }
  
  @include responsive($menuBreakpoint){
    position: absolute;
    z-index: 0;
    left: 50%; 
    transform: translate(-50%, 0);
    padding-top: get-vw(50);
    width: space(46);
  }

  body.--scrolled &{
    @include responsive($menuBreakpoint){
      opacity: 0 !important;
      pointer-events: none !important;
    }
  }

  > #CloseSubmenu{
    @include flex(center);
    position: absolute;
    top: 1em; left: 1em;
    padding: 0.5em;
    color: var(--white);

    @include responsive($menuBreakpoint){
      display: none;
    }

    > span{
      margin-left: 0.25em;
    }

    .Icon[data-name="arrow"]{
      svg > *{ stroke: var(--white) }
    }
  }
  
  > .inner{
    @include flex();
    background: var(--green-d8);

    @include responsive($menuBreakpoint, max){
      padding: 40% 4vw 20%;
      flex-direction: column;
      min-height: 100%;
    }
    
    @include responsive($menuBreakpoint){
      padding: 60px;
      gap: 70px;
      border-radius: var(--radius);
    }
  }

  .head{
    @include flex(flex-start);
    flex-direction: column;
    width: 100%;
    
    @include responsive($menuBreakpoint){
      max-width: get-vw(360);
    }

    > div {
      padding-bottom: 1.25em;

      .title{
        font: 400 25px $nm;
        
        @include responsive($menuBreakpoint, max){
          margin-bottom: 1em;
        }

        @include responsive($menuBreakpoint){
          @include font-vw(40);
          margin-bottom: get-vw(30);
        }

        @include responsive($deskXXL){
          @include font-vw(30);
        }
      }

      p {
        font: 400 16px $nm;
        filter: opacity(0.5);

        @include responsive($menuBreakpoint){
          @include font-vw(16);
        }
      }
    }
  }

  .solutions{
    @include responsive($menuBreakpoint, max){
      padding-top: 3em;
    }

    @include responsive($menuBreakpoint){
      padding-top: 3em;
    }

    &, ul{
      width: 100%;
    }

    ul{
      display: grid;
      
      @include responsive($menuBreakpoint, max){
        grid-gap: 1em;
      }
      
      @include responsive($menuBreakpoint){
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0.5em;
      }

      // See all solutions btn
      li:last-child{
        .Btn{
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background: #0D3636;

          .Icon[data-name="arrow"]{
            display: block;

            svg > *{ stroke: var(--main-green) }
          }
        }
      }
    }
  }
}